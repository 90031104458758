//
// Copyright © 2021 Gaddis Laboratories LLC as part of the GaddisLabs Core Javasript IDE
// The Core IDE is Licensed to customers of GaddisLabs only by contract, and restricted for all others
//
import  React                                       from    "react";
import  { gsap }                                    from    "gsap";
import  GlobalAppManager                            from    "./appManager.js";
import  GlobalWindowManager                         from    "./GaddisLabsCore/windowManager.js";
import  GlobalHeaderManager                         from    "./headerPanelManager.js";
// 
// The VisualPageManager is the React entry point for all actively displayed panels.
//
export  default class VisiblePageManager  extends React.Component {

    constructor(props) {
                         
        console.log("\n**********************************************\n** Starting Incentilock © Marketing Website **\n**********************************************\n");

        super(props);
        this.state =
        {
            forceRedrawToggle:      false,
            mountedAndReady:        false,
            objectName:             [],
            visiblePageRenderers:   [],
        }
        this.registryForUnmountNotification = [];
        this.visiblePageManagerisMounted    = false;
        this.visiblePageDiv                 = null;
        //
        // Special Panel Header div
        //
        this.headerPanelHeight              = 70;
        this.headerPanelDivRef              = null;
        //
        // Background Image Management
        //
        this.visiblePageContainingDivRef    = null;
        this.currentBackgroundImage         = null;
        this.defaultBackgroundColor         = "#443737";
    }
    componentDidMount() {
        GlobalWindowManager.VisablePageManagerWasMountedAlert("GlobalVisablePageManager", this);
        GlobalAppManager.kickStartRunLoop();
    }
    componentWillUnmount ()         {
        console.log("VisiblePageManager.componentWillUnmount() Page is unmounting");
        gsap.globalTimeline.pause();
        for (var i = 0; i < this.registryForUnmountNotification.length; i++) { this.registryForUnmountNotification[i](); }
        this.visiblePageManagerisMounted    = false;
        window.location.reload();
    }
    displayTheMenus()               { GlobalHeaderManager.displayTheMenus(); }
    isVisiblePageManagerMounted()   { return(this.visiblePageManagerisMounted); }
    getDefaultPageColor()           { return(this.defaultBackgroundColor); }
    abortLogoIntro()                { GlobalHeaderManager.abortLogoIntro(); }
    setBodyBackgroundImage(imageRef){
        const   backgroundImageURL      = "url(".concat(imageRef).concat(")");
        const   bodyRef = document.getElementsByTagName("body");
        gsap.set([bodyRef], {css: { backgroundImage: backgroundImageURL,
                                    backgroundRepeat: "no-repeat",
                                    backgroundAttachment: "fixed",
                                    backgroundSize: "cover"}})
        this.currentBackgroundImage = imageRef;
    }
    yourMountIsAcknowledged()           {
        //
        // The rest of the world can now access us, let's update state to reflect that and allow JSX
        // injections into the DOM throigh us
        //
        this.setState({mountedAndReady: true});
        this.visiblePageManagerisMounted    = true;
        this.render();
    }
    forceRedrawOnDOM () { 
        var toggler =  ! this.state.forceRedrawToggle;
        if (this.state.mountedAndReady) this.setState( { forceRedrawToggle: toggler } );
    }
    registerForUnmountNotification(notificationHandler) {
        this.registryForUnmountNotification.push(notificationHandler)
    }
    insertRenderMethodIntoDisplay (requestingObjectName, renderMethod, orderOfPresentation)
    {
        if (orderOfPresentation < this.state.visiblePageRenderers.length)
        {
            this.state.objectName.splice(orderOfPresentation, 0, requestingObjectName);
            this.state.visiblePageRenderers.splice(orderOfPresentation, 0, renderMethod);
            return(orderOfPresentation);
        }
        else {
            this.state.objectName.push(requestingObjectName);
            this.state.visiblePageRenderers.push(renderMethod);
            return(this.state.visiblePageRenderers.length - 1);
        }
    }
    deInsertRenderMethodIntoDisplay (requestingObjectName)
    {
        for (var i = 0; i < this.state.visiblePageRenderers.length; i++) {
            if (this.state.objectName[i] === requestingObjectName) { this.state.objectName.splice(i, 1); this.state.visiblePageRenderers.splice(i,1); }
        }
    }
    render() {
        var compositeJSXarray = [];
        if (this.visiblePageManagerisMounted) {
            for (var i = 0; i < this.state.visiblePageRenderers.length; i++)
            {
              var JSXstringToAppend = this.state.visiblePageRenderers[i]();
              compositeJSXarray.push(JSXstringToAppend);
            }
        }
        return (
            <div 
                key="VisiblePageContainingDivKey"
                id="VisiblePageContainingDivID"
                ref={div => this.visiblePageContainingDivRef = div}
                style={{ 
                    backgroundImage:    "none",
                }}
                >
                {GlobalHeaderManager.render()}
                {compositeJSXarray}
            </div>
        );
    }
}   // end class VisiblePageManager