import  React                           from    "react";
import  { gsap }                        from    "gsap";
import  { MorphSVGPlugin }              from    "gsap/MorphSVGPlugin";

gsap.registerPlugin(MorphSVGPlugin);

//
////////////////////////////////////////
//
// Instructions for use
//  (1) Create an instance of the object myLogo = new IncentiLock(uniqueName), the uniqueName is optional but required for the second instance
//      if you want more than one instance of the logo in the DOM at the same time.
//  (2) Make sure you load the div/svg into the DOM via React JSX, if you are using
//      other environment (angular or straight HTML) you'll need to refactor the SVG code in incentiLockLogoSVG() below
//      to conform to it's standards and get it loaded into the DOM, once in the DOM everything is automatic,
//  (3) Once the div/svg is loaded you should verify via myLogo.isLogoLoadedIntoDOM()
//  (4) If everything is ready then you should move and scale the logo where you want it.
//  (5) Now that it is set in the right place (it's still invisible) you can start the animation with startAnimation(notifyFunction)
//      if you have some work to do after the animation completes, like move it into its final resting place then include a callback
//      function with the "notifyFunction" parameter (make sure you bind it to you this if using stateful objects)
//      like this: this.notifyFunction = this.notifyFunction.bind(this);
//  (6) If you want to replay the animation (at whatever location it is at simply run restartAnimation() and it will reset everyting and start
//      from scratch.
//
////////////////////////////////////////

export default class IncentiLock {

    constructor (uniqueName)
    {
        this.uniqueNameExt                      = (uniqueName === undefined ? "SingleUse" : uniqueName);
        this.containingDivWidth                 = 1100;
        this.containingDivHeight                = 400;
        //
        // GSAP Timers
        //
        this.incentiLockLogoTimeLine            = null;
        //
        // Logo State of Operations
        //
        this.JSXLoadedIntoDomFlag               = false;
        this.animationIsRunning                 = false;
        this.notifyWhenLogoAnimationComplete    = null;
        this.pausedBeforeAction                 = false;
        this.notificationHandler                = this.notificationHandler.bind(this);
    }
    //
    // External Methods on Logo
    //
    loadLogoIntoJSX() {
        if (this.isLogoLoadedIntoDOM()) this.uniqueNameExt = this.uniqueNameExt.concat(Math.floor((Math.random() * 100).toString()));
        this.JSXLoadedIntoDomFlag = true; 
        return(this.incentiLockLogoSVG());
    }
    isLogoLoadedIntoDOM() {
        const IncentiLockLogoDIV = document.getElementById("IncentiLockLogoDIVID" + this.uniqueNameExt);
        if (IncentiLockLogoDIV == null) return(false);
        return(true);
    }
    resumeAnimation(notifyFunction) {
        if (notifyFunction === undefined)   this.notifyWhenLogoAnimationComplete = null; 
        else                                this.notifyWhenLogoAnimationComplete = notifyFunction;

        if (this.pausedBeforeAction && this.incentiLockLogoTimeLine != null) {
            this.incentiLockLogoTimeLine.resume();
        }
        this.pausedBeforeAction = false;
    }
    buildTimeline(callbackWhenAnimationComplete) {

        this.notifyWhenLogoAnimationComplete = (callbackWhenAnimationComplete === undefined ? null : callbackWhenAnimationComplete);

        const IncentiLockLogoDIV                    = document.getElementById("IncentiLockLogoDIVID".concat(this.uniqueNameExt));
        const IncentiLockLogoSVG                    = document.getElementById("IncentiLockLogoSVGID".concat(this.uniqueNameExt));
        const IncentiLockLOCK                       = document.getElementById("IncentiLockLOCK".concat(this.uniqueNameExt));
        const IncentiLockFullText                   = document.getElementById("IncentiLockFullText".concat(this.uniqueNameExt));
        const LOCKhandle                            = document.getElementById("LOCKhandle".concat(this.uniqueNameExt));
        const LOCKkeyhole                           = document.getElementById("LOCKkeyhole".concat(this.uniqueNameExt));
        const LOCKmiddleWhiteStrip                  = document.getElementById("LOCKmiddleWhiteStrip".concat(this.uniqueNameExt));
        const LOCKbody                              = document.getElementById("LOCKbody".concat(this.uniqueNameExt));
        const LOCKwhiteSquares                      = document.getElementById("LOCKwhiteSquares".concat(this.uniqueNameExt));
        const LOCKstripUnderSquares                 = document.getElementById("LOCKstripUnderSquares".concat(this.uniqueNameExt));
        const LOCKmainBody                          = document.getElementById("LOCKmainBody".concat(this.uniqueNameExt));

        const IncentiLockMorphedText                = document.getElementById("IncentiLockMorphedText".concat(this.uniqueNameExt));
        const InLockText_L                          = document.getElementById("InLockText_L".concat(this.uniqueNameExt));
        const InLockText_O                          = document.getElementById("InLockText_O".concat(this.uniqueNameExt));
        const InLockText_SecondC                    = document.getElementById("InLockText_SecondC".concat(this.uniqueNameExt));
        const InLockText_K                          = document.getElementById("InLockText_K".concat(this.uniqueNameExt));

        const IncentiLockTagline                    = document.getElementById("IncentiLockTagline".concat(this.uniqueNameExt));
        const tag_Making                            = document.getElementById("tag_Making".concat(this.uniqueNameExt));
        const tag_Incentive                         = document.getElementById("tag_Incentive".concat(this.uniqueNameExt));
        const tag_Compliance                        = document.getElementById("tag_Compliance".concat(this.uniqueNameExt));
        const tag_Simple                            = document.getElementById("tag_Simple".concat(this.uniqueNameExt));

        this.incentiLockLogoTimeLine = gsap.timeline({ paused: true });
            this.incentiLockLogoTimeLine.addLabel("StartOfTimeLine");
            
            this.incentiLockLogoTimeLine.set(IncentiLockLogoDIV,                {opacity: 1.0});
            this.incentiLockLogoTimeLine.set(IncentiLockLogoSVG,                {opacity: 1.0});
            this.incentiLockLogoTimeLine.set(IncentiLockLOCK,                   {scale: 0.7, x: +240, y: +350, opacity: 0.0});
            this.incentiLockLogoTimeLine.set(IncentiLockFullText,               {y: -300, opacity: 0.0});
            this.incentiLockLogoTimeLine.set(IncentiLockMorphedText,            {opacity: 0.0});
            this.incentiLockLogoTimeLine.set(LOCKhandle,                        {y: -400});
            this.incentiLockLogoTimeLine.set(LOCKkeyhole,                       {rotate: "180%", transformOrigin: "50% 50%"});
            this.incentiLockLogoTimeLine.set(IncentiLockTagline,                {x: -240, scale: 1.2});
            this.incentiLockLogoTimeLine.set([  tag_Making, tag_Incentive,
                                                tag_Compliance, tag_Simple],    {opacity: 0.0});
            this.incentiLockLogoTimeLine.to(IncentiLockFullText,                {opacity: 1.0, duration: 1.0}, ">");
            this.incentiLockLogoTimeLine.to(IncentiLockLOCK,                    {opacity: 1.0, duration: 1.0, onComplete: ()=> {if(this.pausedBeforeAction) {this.notificationHandler(); } }}, "=+0.5");
            this.incentiLockLogoTimeLine.addLabel("JustbeforeLockAnimation");
            this.incentiLockLogoTimeLine.to(LOCKhandle,                         {y: 0, delay: 0.5, duration: 1.0}, ">");
            this.incentiLockLogoTimeLine.to(LOCKkeyhole,                        {rotate: "0%", duration: 1.0, transformOrigin: "50% 50%"}, ">");            
            this.incentiLockLogoTimeLine.to(LOCKstripUnderSquares,              {morphSVG: {shape: InLockText_L,        shapeIndex: "auto"}, scale: 1.42,   x: -100,    y: -380, stroke: "none", fill: "#98c93d", opacity: 1.0, duration: 2.0}, "=+0.5");
            this.incentiLockLogoTimeLine.to(LOCKhandle,                         {morphSVG: {shape: InLockText_O,        shapeIndex: "auto"}, scale: 1.42,   x: -240,    y: -910, stroke: "none", fill: "#98c93d", opacity: 1.0, duration: 2.0}, "=-2");
            this.incentiLockLogoTimeLine.to(LOCKmainBody,                       {morphSVG: {shape: InLockText_SecondC,  shapeIndex: "auto"}, scale: 1.42,   x: -270,    y: -450, stroke: "none", fill: "#98c93d", opacity: 1.0, duration: 2.0}, "=-2");
            this.incentiLockLogoTimeLine.to(LOCKkeyhole,                        {morphSVG: {shape: InLockText_K,        shapeIndex: "auto"}, scale: 1.42,   x: +110,    y: -200, stroke: "none", fill: "#98c93d", opacity: 1.0, duration: 2.0}, "=-2");
            this.incentiLockLogoTimeLine.to([   LOCKbody,
                                                LOCKwhiteSquares,
                                                LOCKmiddleWhiteStrip],          {opacity: 0.0, duration: 1.0}, "=-2");
            this.incentiLockLogoTimeLine.to([   tag_Making, tag_Incentive,
                                                tag_Compliance, tag_Simple],    {stagger: 0.2, opacity: 1.0, duration: 2.0, onComplete: ()=> {this.notificationHandler()}});

            this.incentiLockLogoTimeLine.addLabel("EndOfTimeLine");
        //
        // This will be called by the InFocus manager, return the timeline to it for management
        //
        return(this.incentiLockLogoTimeLine);
    }
    stopMainAnimation()         {   if (this.incentiLockLogoTimeLine != null) { this.incentiLockLogoTimeLine.seek("EndOfTimeLine", false); } this.notificationHandler(); }
    restartAnimation()          {     
        if (this.incentiLockLogoTimeLine != null) {
            this.incentiLockLogoTimeLine.reverse("StartOfTimeLine");                     
            this.incentiLockLogoTimeLine.play();
        }
    }
    getDivRefName()             { return ("IncentiLockLogoDIVID" + this.uniqueNameExt); }
    notificationHandler()       {
        this.animationIsRunning = false;
        if (this.notifyWhenLogoAnimationComplete != null) this.notifyWhenLogoAnimationComplete();
    }
    incentiLockLogoSVG() { 
        return (
        <div
            id={"IncentiLockLogoDIVID" + this.uniqueNameExt}
            key={"IncentiLockLogoDIVKey"  + this.uniqueNameExt}
            style={{ 
                position:           "absolute",
                overflow:           "visible",
                height:             "100%",
                width:              "100%",
                zIndex:             10000000,
                top:                "0px",
                left:               "0px",
                opacity:            "0.0",
            }}>  
            <svg
                id={"IncentiLockLogoSVGID" + this.uniqueNameExt}
                key={"IncentiLockLogoSVGKey" + this.uniqueNameExt}
                style={{ 
                    position:       "absolute",
                    height:         "100%",
                    width:          "100%",
                    zIndex:         10000000,
                    bottom:         "0px",
                    left:           "0px",
                    opacity:        "0.0",
                }}   
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="1.5"
                clipRule="evenodd"
                strokeWidth="0px" 
                stroke="none"
                viewBox="0 0 4022 2251"
                >
                    <g>
                        <g id={"IncentiLockFullText" + this.uniqueNameExt}>
                            <path   id={"InLockText_I" + this.uniqueNameExt}        fill="#55585a"  d="M2.973,1151.77l109.229,0.239l-2.006,590.45l-110.196,0l2.973,-590.689Z"/>
                            <path   id={"InLockText_firstN" + this.uniqueNameExt}   fill="#55585a"  d="M530.877,1738.82c-0,-0 6.485,-239.358 0.664,-301.535c-2.482,-26.513 -9.958,-43.946 -35.587,-71.523c-30.331,-32.637 -87.469,-38.291 -101.377,-37.045c-75.593,6.773 -110.241,67.722 -110.241,67.722l0.448,-56.223l-102.545,-1.192l-1.449,402.738l107.933,0.701c-0,0 -2.393,-204.367 1.241,-255.306c2.057,-28.849 27.215,-58.744 69.317,-62.207c38.867,-3.197 67.375,26.994 67.77,54.72c0.787,55.366 -1.185,259.072 -1.185,259.072l105.011,0.078Z"/>
                            <path   id={"InLockText_firstC" + this.uniqueNameExt}   fill="#55585a"  d="M915.164,1693.99c-36.254,30.253 -82.912,48.469 -133.791,48.469c-115.371,0 -209.037,-93.667 -209.037,-209.038c-0,-115.371 93.666,-209.037 209.037,-209.037c50.198,-0 96.287,17.732 132.333,47.263l-67.425,83.038c-16.689,-19.742 -39.619,-31.938 -64.908,-31.938c-50.996,0 -92.398,49.592 -92.398,110.674c0,61.083 41.402,110.675 92.398,110.675c24.912,-0 47.534,-11.834 64.162,-31.065l69.629,80.959Z"/>
                            <path   id={"InLockText_E" + this.uniqueNameExt}        fill="#55585a"  d="M1299.27,1664.93c-37.665,47.383 -94.232,77.533 -157.434,77.533c-113.265,0 -205.222,-96.831 -205.222,-216.101c-0,-119.269 91.957,-216.101 205.222,-216.101c113.265,0 205.222,96.832 205.222,216.101c0,10.734 -0.745,21.287 -2.184,31.609l-169.669,1.126l3.414,2.913l-123.001,-0.012c11.887,45.72 46.016,78.778 86.218,78.778c30.236,-0 57.037,-18.699 73.524,-47.424l83.91,71.578Zm-74.005,-183.525l-166.853,-0.016c13.865,-40.818 46.015,-69.441 83.424,-69.441c37.413,0 69.567,28.631 83.429,69.457Z"/>
                            <path   id={"InLockText_SecondN" + this.uniqueNameExt}  fill="#55585a"  d="M1737.82,1738.82c-0,-0 6.484,-239.358 0.664,-301.535c-2.482,-26.513 -9.958,-43.946 -35.587,-71.523c-30.332,-32.637 -87.469,-38.291 -101.377,-37.045c-75.593,6.773 -110.241,67.722 -110.241,67.722l0.448,-56.223l-102.545,-1.192l-1.449,402.738l107.932,0.701c0,0 -2.392,-204.367 1.242,-255.306c2.057,-28.849 27.215,-58.744 69.316,-62.207c38.868,-3.197 67.376,26.994 67.771,54.72c0.787,55.366 -1.185,259.072 -1.185,259.072l105.011,0.078Z"/>
                            <path   id={"InLockText_T" + this.uniqueNameExt}        fill="#55585a"  d="M1864.88,1217.8l109.27,-0.953c0,-0 -3.883,317.528 -1.8,388.239c0.38,12.914 -0.212,29.294 10.958,35.788c14.964,8.7 87.414,6.398 87.414,6.398l0.469,94.389c0,-0 -105.483,2.729 -135.831,-1.924c-17.483,-2.681 -31.701,-8.859 -44.587,-18.366c-13.097,-9.662 -22.621,-35.545 -22.826,-51.478c-0.589,-45.83 -1.936,-236.142 -1.936,-236.142l-75.005,-0.192l0.141,-95.092l74.889,-0.784l-1.156,-119.883Z"/>
                            <path   id={"InLockText_SecondI" + this.uniqueNameExt}  fill="#55585a"  d="M2015.97,1338.3l249.716,-0.973l0.033,405.018l-113.399,0.114l1.627,-308.037l-137.314,-0.912l-0.663,-95.21Z"/>
                            <circle id={"InLockText_dot" + this.uniqueNameExt}      fill="#55585a"  cx="2214.48" cy="1184.68" r="75.597"/>
                        </g>
                        <g id={"IncentiLockMorphedText" + this.uniqueNameExt}> 
                            <path   id={"InLockText_L" + this.uniqueNameExt}        fill="#98c93d"  d="M2449.61,1634.2l0,-484.672l-108.24,-0l0,591.92l66.55,0l0,0.99l268.572,0.016l0.006,-108.24l-226.888,-0.014Z"/>
                            <path   id={"InLockText_O" + this.uniqueNameExt}        fill="#98c93d"  d="M2912.76,1324.38c115.371,-0 209.038,93.666 209.038,209.037c-0,115.371 -93.667,209.038 -209.038,209.038c-115.371,0 -209.038,-93.667 -209.038,-209.038c0,-115.371 93.667,-209.037 209.038,-209.037Zm-0,98.363c50.996,0 92.398,49.592 92.398,110.674c0,61.083 -41.402,110.675 -92.398,110.675c-50.996,-0 -92.398,-49.592 -92.398,-110.675c-0,-61.082 41.402,-110.674 92.398,-110.674Z"/>
                            <path   id={"InLockText_SecondC" + this.uniqueNameExt}  fill="#98c93d"  d="M3497.13,1693.99c-36.254,30.253 -82.912,48.469 -133.79,48.469c-115.371,0 -209.038,-93.667 -209.038,-209.038c-0,-115.371 93.667,-209.037 209.038,-209.037c50.198,-0 96.287,17.732 132.332,47.263l-67.424,83.038c-16.69,-19.742 -39.62,-31.938 -64.908,-31.938c-50.996,0 -92.398,49.592 -92.398,110.674c-0,61.083 41.402,110.675 92.398,110.675c24.911,-0 47.533,-11.834 64.161,-31.065l69.629,80.959Z"/>
                            <path   id={"InLockText_K" + this.uniqueNameExt}        fill="#98c93d"  d="M3647.88,1534.97l-0.698,207.492l-110.196,0l2.974,-596.889l109.228,0.241l-1.213,360.729l134.704,-162.735l132.953,-1.485l-138.841,176.802l144.329,220.475l-134.691,2.862l-138.549,-207.492Z"/>
                        </g> 
                        <g id={"IncentiLockLOCK" + this.uniqueNameExt}>
                            <path   id={"LOCKhandle" + this.uniqueNameExt}                  fill="none"  stroke="#55585a" strokeWidth="166.67px"   d="M2497.24,1027.71c-0,0 -18.34,-310.522 9.936,-437.122c29.02,-129.928 93.096,-242.288 169.022,-321.696c85.981,-89.925 262.333,-189.775 435.399,-185.424c151.221,3.801 312.089,60.251 428.52,182.043c94.003,98.331 171.676,250.261 181.201,369.623c15.538,194.713 9.345,919.764 9.345,919.764"/>
                            <rect   id={"LOCKmiddleWhiteStrip" + this.uniqueNameExt}        fill="#778899"  x="2373.39" y="1049.59" width="1496.6"  height="106.547"/>
                            <path   id={"LOCKmainBody" + this.uniqueNameExt}                fill="#55585a"  d="M3905.81,1202.45c-0,-39.917 -32.408,-72.325 -72.326,-72.325l-1443.68,-0c-39.918,-0 -72.326,32.408 -72.326,72.325l0,976.14c0,39.917 32.408,72.325 72.326,72.325l1443.68,0c39.918,0 72.326,-32.408 72.326,-72.325l-0,-976.14Z"/>
                            <g id={"LOCKbody" + this.uniqueNameExt}> 
                                <rect   id={"LOCKcenterBox" + this.uniqueNameExt}           fill="#2F4F4F"  x="2798.27" y="1131.63" width="626.743" height="1117.46"/>
                                <rect   id={"LOCKtopGreenStrip" + this.uniqueNameExt}       fill="#97c93c"  x="2313.46" y="979.842" width="1596.36" height="95.863"/>
                            </g>
                            <g id={"LOCKwhiteSquares" + this.uniqueNameExt}>
                                <rect   id={"LOCKfirstWhiteSquare" + this.uniqueNameExt}    fill="#a9a9a9"  x="2911.13" y="1211.29" width="107.932" height="102.122"/>
                                <rect   id={"LOCKsecondWhiteSquare" + this.uniqueNameExt}   fill="#a9a9a9"  x="3057.87" y="1211.29" width="107.932" height="102.122"/>
                                <rect   id={"LOCKthirWhiteSquare" + this.uniqueNameExt}     fill="#a9a9a9"  x="3204.22" y="1211.29" width="107.932" height="102.122"/>
                            </g>
                            <path   id={"LOCKstripUnderSquares" + this.uniqueNameExt}       fill="#98c93d"  d="M3333.5,1393.98c0,-10.723 -8.705,-19.428 -19.428,-19.428l-404.863,-0c-10.723,-0 -19.428,8.705 -19.428,19.428c0,10.722 8.705,19.428 19.428,19.428l404.863,-0c10.723,-0 19.428,-8.706 19.428,-19.428Z"/>
                            <path   id={"LOCKkeyhole" + this.uniqueNameExt}                 fill="#121212"  d="M3045.82,1733.37c-26.423,-19.965 -43.511,-51.643 -43.511,-87.282c0,-60.342 48.99,-109.332 109.333,-109.332c60.342,0 109.332,48.99 109.332,109.332c-0,33.647 -15.232,63.765 -39.167,83.824l0.416,221.658c0.07,37.528 -30.341,68.053 -67.869,68.124c-37.528,0.07 -68.053,-30.341 -68.124,-67.869l-0.41,-218.455Z"/>
                        </g>
                    </g>
                    <g id={"IncentiLockTagline" + this.uniqueNameExt} fontSize="160px" fontWeight="900" fontFamily="comfortaa" fill="#98c93d">
                        <text id={"tag_Making" + this.uniqueNameExt}       opacity="0.0"   x="315px"    y="1450px" dy="1.2em">Making</text>
                        <text id={"tag_Incentive" + this.uniqueNameExt}    opacity="0.0"   x="1025px"    y="1450px" dy="1.2em">Incentive</text>
                        <text id={"tag_Compliance" + this.uniqueNameExt}   opacity="0.0"   x="1875px"   y="1450px" dy="1.2em">Compliance</text>
                        <text id={"tag_Simple" + this.uniqueNameExt}       opacity="0.0"   x="2975px"   y="1450px" dy="1.2em">Simple</text>
                    </g>
            </svg>
        </div>
        );
    } // end incentiLockLogoSVG
}