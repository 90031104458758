//
// This class will set a browser resize listener for our app that will trigger a resize on elements affected when the window resizes
//
//  Note: The React.useLayoutEffect will trigger synchronously after all DOM mutations so as the window changes size
//        this function will trigger.
//
import  React                       from  "react";
import  { gsap }                    from  "gsap";

class WindowManager {

  constructor() {
    //
    // Global Object rendevous stuff (reported after mounting), requires registered objects to have a allObjectsAreReady() method
    //
    this.globalVisablePageManagerIsRegistered           = false;
    this.globalVisablePageManagerClassName              = null;
    this.globalVisablePageManagerObject                 = null;
    this.globalVisablePageManagerMountedNotifyRegistry  = [];
    this.isDesktop                                      = true;
    this.isMobile                                       = false;
    this.OSName                                         = "Unknown OS";
    this.BrowserName                                    = "Unknown Browser";
    this.touchPoints                                    = window.navigator.maxTouchPoints;
    //
    // Window resize event handler stuff
    //
    this.windowResizeHandlers                           = [];
    this.orientationMode                                = "Unknown Mode";
    this.windowSize                                     = {width: window.innerWidth, height: window.innerHeight};
    this.resizeEventHandler                             = this.resizeEventHandler.bind(this);
    //
    // Window reload event handler stuff
    //
    this.windowReloadHandlers                           = [];
    this.reloadEventHandler                             = this.reloadEventHandler.bind(this);
    //
    // Screen Orientation Event Handlers
    //
    this.screenOrientationChangeHandler                 = this.screenOrientationChangeHandler.bind(this);
    //
    // Register event handlers
    //
    window.addEventListener("resize", this.resizeEventHandler);
    window.addEventListener("beforeunload", this.reloadEventHandler);
    this.determineOperatingEnvironment();
  }
  //
  // Look for mobile or desktop
  //
  determineOperatingEnvironment()
  {
    const userAgent = window.navigator.userAgent;
    const isAndroid = (userAgent.toLowerCase().match(/(android);?[\s/]+([\d.]+)?/) == null ? false : true);
    const isIOS     = (userAgent.toLowerCase().match(/ipad|iphone|ipod/) == null ? false : true);
    this.isDesktop  = (isIOS || isAndroid ? false : true);
    this.isMobile   = (isIOS || isAndroid ? true : false);
    //
    // Detect OS
    //
    if (userAgent.indexOf("Win") !== -1)        this.OSName = "Windows";
    if (userAgent.indexOf("Mac") !== -1)        this.OSName = "Mac";
    if (userAgent.indexOf("Linux") !== -1)      this.OSName = "Linux";
    if (userAgent.indexOf("Android") !== -1)    this.OSName = "Android";
    if (userAgent.indexOf("like Mac") !== -1)   this.OSName = "iOS";
    if (userAgent.indexOf("iPad") !== -1)       this.OSName = "iPadOS";
    //
    // Detect Browser
    //
    if (userAgent.indexOf("Windows NT") !== -1) this.BrowserName = "Explorer";
    if (userAgent.indexOf("Firefox") !== -1)    this.BrowserName = "Firefox";
    if (userAgent.indexOf("Safari") !== -1)     this.BrowserName = "Safari";
    if (userAgent.indexOf("Chrome") !== -1)     this.BrowserName = "Chrome";
    if (userAgent.indexOf("Opera") !== -1)      this.BrowserName = "Opera";
    if (userAgent.indexOf("Edg") !== -1)        this.BrowserName = "Edge"; // For some weird ass reason the string on MAC is Edg not Edge

    if (this.isDesktop)   console.log("Using " + this.getOSName() + " desktop computer, browser = " + this.getBrowserName());
    else                  console.log("Using " + this.getOSName() + " mobile device, browser = "    + this.getBrowserName());

    console.log("GSAP Version:", gsap.version);
    console.log("React Version:", React.version);
    console.log("Node Version:", "17.3.1");
    console.log("NPM Version:", "8.3.0");

    this.screenOrientationChangeHandler();
  }
  isThisADesktop()      { return(this.isDesktop); }
  isThisMobileDevice()  { return(this.isMobile); }
  getOSName()           { return(this.OSName); }
  getBrowserName()      { return(this.BrowserName); }
  //
  // Global Object rendevous stuff (reported after mounting), requires registered objects to have a globalVisablePageManagersAreMounted() method
  //
  // You register yourself after you get the componentDidMount() call from React. Then 'this' has been instantiated within React.
  //
  registerForGlobalVisablePageManagerNotifyWhenMounted(objectMethodReference) { 
    this.globalVisablePageManagerMountedNotifyRegistry.push(objectMethodReference);
  }
  //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // This method gets called when the visiblePageManager receives the componentDidMount() message from React.
  // React creates a 'new'ed version of the visiblePageManager and keeps that in its local memory noogin. In order
  // to interact with the dom we need that 'this' reference. When that is done it is registered here and we distribute
  // that reference to any object that has registered to receive notice.
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  VisablePageManagerWasMountedAlert(nameOfObjectRegistering, objectReference) { // GlobalVisablePageManager
    this.globalVisablePageManagerObject         = objectReference;
    this.globalVisablePageManagerClassName      = nameOfObjectRegistering;
    this.globalVisablePageManagerIsRegistered   = true;
    this.globalVisablePageManagerObject.yourMountIsAcknowledged();
    this.notifyMountWatchers();
  }
  isPageMounted() { 
    if (this.globalVisablePageManagerObject != null) return(this.globalVisablePageManagerObject.isVisiblePageManagerMounted()); else return(false);
  }
  notifyMountWatchers() {
    for (var i = 0; i < this.globalVisablePageManagerMountedNotifyRegistry.length; i++) {
      if (this.globalVisablePageManagerMountedNotifyRegistry[i] != null) {
        this.globalVisablePageManagerMountedNotifyRegistry[i].globalVisablePageManagerWasMounted(this.globalVisablePageManagerObject);
      }
      this.globalVisablePageManagerMountedNotifyRegistry[i] = null;
    }
  }
  isScreenInLandscapeOrientation()  { return((this.orientationMode === "Landscape" ? true : false)); }
  isScreenInPortraitOrientation()   { return((this.orientationMode === "Portrait" ? true : false)); }
  screenOrientationChangeHandler()  {
      if (window.innerHeight > window.innerWidth) { // Protrait mode
        if (this.orientationMode === "Landscape" || this.orientationMode === "Unknown Mode") console.log("Portrait orientation");
        this.orientationMode = "Portrait";
      }
      else {
        if (this.orientationMode === "Portrait" || this.orientationMode === "Unknown Mode") console.log("Landscape orientation");
        this.orientationMode = "Landscape";
      }
  }
  //
  //////////////////////////////////////
  // Handles browser reloading events //
  //////////////////////////////////////
  //
  registerWindowReloadHandler(reloadHandlerMethod) {
    this.windowReloadHandlers.push(reloadHandlerMethod); 
    return(true); 
  }
  reloadEventHandler(event) {
    event.preventDefault();
    for (var i = 0; i < this.windowReloadHandlers.length; i++) { 
      this.windowReloadHandlers[i]();
    }
  }
  //
  ////////////////////////////////////
  // Handles resized browser window //
  ////////////////////////////////////
  //
  registerWindowResizeHandler(resizeHandlerMethod) {
    this.windowResizeHandlers.push(resizeHandlerMethod); 
    return(true); 
  }
  resizeEventHandler()
  {
    this.screenOrientationChangeHandler();

    if (this.windowSize.width === window.innerWidth && this.windowSize.height === window.innerHeight) return; // Must be a change
    
    if (this.windowSize.width !== window.innerWidth || this.windowSize.height !== window.innerHeight) {
      this.windowSize = {width: window.innerWidth, height: window.innerHeight};
      for (var i = 0; i < this.windowResizeHandlers.length; i++) { 
        this.windowResizeHandlers[i](this.windowSize);
      }
    }
      
  }
  getCurrentWindowSize() {
    this.windowSize = {width: window.innerWidth, height: window.innerHeight};
    return(this.windowSize);
  }
} // end class WindowManager
const           GlobalWindowManager = new WindowManager();
export default  GlobalWindowManager;