import  GlobalWindowManager       from    "./GaddisLabsCore/windowManager.js"
import  IncentiLockLandingPage    from    "./incentilockLandingPage.js"
//
// This class manages the Incentilock Website
//
class AppManagerObj {

  constructor () {
    //
    // This guy controls all visable display on the screen, it is the React "injector class"
    //
    this.globalVisablePageManager                 = null;
    //
    // We use these to let our web component ecosystem (through React) warm up before we rock-&-roll
    //
    this.incentiLockLandingManager                = null;
    this.incentiLockLandingManagerMountedAndReady = false;
    //
    // Track active managers
    //
    this.activeManager                            = null;
    this.visiblePageManagerDismounted             = this.visiblePageManagerDismounted.bind(this);

    GlobalWindowManager.registerForGlobalVisablePageManagerNotifyWhenMounted(this);

    this.runLoopWasKickstarted                    = false;
    this.runLoopPaused                            = false;
  }
  globalVisablePageManagerWasMounted (VisualPageManagerInReact) {
    this.globalVisablePageManager   = VisualPageManagerInReact;
    this.globalVisablePageManager.registerForUnmountNotification(this.visiblePageManagerDismounted);
    this.pageManagerMountedAndReady = true;
    this.testManagerMountedAndReady = true;
  }
  visiblePageManagerDismounted() {
    console.log("GlobalAppManager: Received Notification of VisablePageManager Dismount");
  }
  //
  // Kick starting the runLoop is called in the injected JSX of the visual page manager
  //
  kickStartRunLoop() {
    if (this.incentiLockLandingManager == null) {
      this.createSubPanels();
    }
    this.runLoopWasKickstarted = true;
    return;
  }
  createSubPanels() {
    this.incentiLockLandingManager      = new IncentiLockLandingPage();
  }
  kickStartWebPage() {
    console.log("Kick Starting Web Page");
    this.activeManager = this.incentiLockLandingManager;
    this.incentiLockLandingManager.goActive();
  }
  moveToIncentiLockLanding() {
    if (this.activeManager == null) { return; }
    if (this.activeManager === this.incentiLockLandingManager)
    { 
      this.incentiLockLandingManager.homeButtonScrollToTop();
    }
  }
  skipAnimations() { this.incentiLockLandingManager.skipAnimations(); }
  showAnimations() { this.incentiLockLandingManager.showAnimations(); }
} // End Class AppManagerObj

const GlobalAppManager = new AppManagerObj();
export default GlobalAppManager;