import  GlobalPanelManager              from    "./panelManager.js";

export default class  pageManager  {
    constructor() {
      this.currentActivePageIndex           = null;
      this.pageArray                        = [];       // The page array holds a set of object(s) {pageName: pageNameArg, panelArray: []}
      this.allPanelsAreMounted              = false;
      this.checkForPanelDismounts           = this.checkForPanelDismounts.bind(this);
      this.mountNewPage                     = this.mountNewPage.bind(this);
      this.checkForPanelMounts              = this.checkForPanelMounts.bind(this);
      this.allPanelsMountingComplete        = this.allPanelsMountingComplete.bind(this);
    }
    setCallbackIfPageBeingTransitioned(pageName, callbackMethod) {
      
      const pageIndex = this.locatePageIndex(pageName);
  
      if (pageIndex === -1) return;
  
      if (callbackMethod == null || callbackMethod === undefined) { this.pageArray[pageIndex].callbackIfPageBeingTransitioned = null; return; }
      this.pageArray[pageIndex].callbackIfPageBeingTransitioned = callbackMethod;
    }
    addPanelToPage(pageNameArg, panelNameArg)
    {
      const pageIndex = this.locatePageIndex(pageNameArg);
      if (pageIndex === -1)
      { // New Page
        const newPageEntry = { pageName: pageNameArg, panelArray: [],  callbackIfPageBeingTransitioned: null};
        newPageEntry.panelArray.push(panelNameArg);
        this.pageArray.push(newPageEntry)
      }
      else
      { // Existing page
        const panelIndex = this.locatePanelIndexInPage(pageIndex, panelNameArg);
        if (panelIndex === -1)  this.pageArray[pageIndex].panelArray.push(panelNameArg);
        else { // No duplicates
          console.log("addPanelToPage(pageNameArg, panelNameArg, panelIndex) Duplicate Panel Name ignoring", pageNameArg, panelNameArg, panelIndex);
          return;
        } 
      }
    }
    currentActivePage() { return(this.pageArray[this.currentActivePageIndex].pageName); }
    makePageActive(pageName, mountCompleteCallBackRoutine)
    {
      if (GlobalPanelManager.isPageInTransition()) { console.log("makePageActive(): Page Change is in Transition, please wait"); return; }
      const oldPageIndex = this.currentActivePageIndex;
      this.currentActivePageIndex = this.locatePageIndex(pageName);
      if (this.pageMountChecker(pageName, mountCompleteCallBackRoutine, oldPageIndex) === false) { this.currentActivePageIndex = oldPageIndex; return; }
      GlobalPanelManager.setPageIsInTransition();
      console.log("Changing Page to [".concat(pageName).concat("]"));
      //
      // At this point all the tests have passed and we begin the transition process. We prevent certain actions by the old page during this time, like setting a panel to active
      //
      // First we dismount all the old panels from the currentActivePageName (unless this is the first mount of any page) and make sure they have left the DOM before proceeding.
      //
      if (oldPageIndex != null) {
        if (this.pageArray[oldPageIndex].panelArray.length > 0)
        {
          if (this.pageArray[oldPageIndex].callbackIfPageBeingTransitioned != null)
          {
            this.pageArray[oldPageIndex].callbackIfPageBeingTransitioned(this.pageArray[oldPageIndex].pageName);
          }
  
          for (var i = this.pageArray[oldPageIndex].panelArray.length - 1; i >= 0; i--) {
            GlobalPanelManager.panelGoingOutOfFocusAndOutOfDom(this.pageArray[oldPageIndex].panelArray[i]);
            GlobalPanelManager.makePanelInActive(this.pageArray[oldPageIndex].panelArray[i], "exitStageLeft");
          }
          this.checkForPanelDismounts(oldPageIndex, mountCompleteCallBackRoutine);
        }
      }
      else { // Accounts for first mount
        this.mountNewPage(mountCompleteCallBackRoutine);
      }
    }
    checkForPanelDismounts(oldPageIndex, mountCompleteCallBackRoutine) {
  
      var areAllPanelsDismountedAndStill = this.pageArray[oldPageIndex].panelArray.length * 2;
      for (var i = 0; i < this.pageArray[oldPageIndex].panelArray.length; i++) {
  
        //console.log("Panel [".concat(this.pageArray[oldPageIndex].panelArray[i]).concat("] status [").concat((GlobalPanelManager.isThisPanelMounted(this.pageArray[oldPageIndex].panelArray[i]) === false ? "NOT Mounted] [" : "Still Mounted] [").concat((GlobalPanelManager.isPanelInMotion(this.pageArray[oldPageIndex].panelArray[i]) === false ? "NOT in Motion]" : "Still in Motion]"))));
  
        if (GlobalPanelManager.isThisPanelMounted(this.pageArray[oldPageIndex].panelArray[i]) === false) {
          areAllPanelsDismountedAndStill--;
        }
        if (GlobalPanelManager.isPanelInMotion(this.pageArray[oldPageIndex].panelArray[i]) === false) {
          areAllPanelsDismountedAndStill--;
        }
      }
      if (areAllPanelsDismountedAndStill <= 0)    { this.mountNewPage(mountCompleteCallBackRoutine); return; }
      else                                        { setTimeout(this.checkForPanelDismounts, 100, oldPageIndex, mountCompleteCallBackRoutine); }
    }
    mountNewPage(callBackRoutine) {
      this.allPanelsAreMounted = false;
      //
      // Now mount the new page then wait for all panels to be loaded
      //
      if (this.pageArray[this.currentActivePageIndex].panelArray.length > 0) {
        for (var i = 0; i < this.pageArray[this.currentActivePageIndex].panelArray.length; i++)
        {
          GlobalPanelManager.makePanelActive(this.pageArray[this.currentActivePageIndex].panelArray[i], i, "gracefulEnter");
        }
      }
      this.checkForPanelMounts(callBackRoutine);
    }
    checkForPanelMounts(callBackRoutine) {
      var allPanelsMountedChecker = true;
      for (var i = 0; i < this.pageArray[this.currentActivePageIndex].panelArray.length; i++) {
        if (GlobalPanelManager.isThisPanelMounted(this.pageArray[this.currentActivePageIndex].panelArray[i]) === false) {
          allPanelsMountedChecker = false;
        }
      }
      if (allPanelsMountedChecker === true) { this.allPanelsMountingComplete(callBackRoutine); }
      else                                  { setTimeout(this.checkForPanelMounts, 100); }
    }
    allPanelsMountingComplete(callBackRoutine) {
      //console.log("allPanelsMountingComplete() called");
      this.allPanelsAreMounted = true;
      GlobalPanelManager.setPagehasCompletedTransition();
      callBackRoutine();
    }
    //
    // Internal Utilities
    //
    locatePageIndex(pageNameArg) {
      for (var i = 0; i < this.pageArray.length; i++)                       { if (this.pageArray[i].pageName === pageNameArg) return(i); }
      return(-1);
    }
    locatePanelIndexInPage(pageIndex, panelNameArg) {
      for (var i = 0; i < this.pageArray[pageIndex].panelArray.length; i++) { if (this.pageArray[pageIndex].panelArray[i] === panelNameArg) return i; }
      return (-1);
    }
    numberOfPanelsInPage(pageName) {
      const pageIndex = this.locatePageIndex(pageName);
      if (pageIndex === -1) return (0);
      else return(this.pageArray[pageIndex].panelArray.length);
    }
    printAllPanelsInPage(pageName, optionalComment) {
      const comment = (optionalComment === undefined ? null : optionalComment);
      const pageIndex = this.locatePageIndex(pageName);
      if (pageIndex === -1) return;
  
      var pageListText = "Panels in Page[".concat(this.pageArray[pageIndex].pageName).concat("]");
  
      if (comment != null)                                                              { pageListText = pageListText.concat(" [").concat(comment).concat("]  :  "); }
      else                                                                              { pageListText = pageListText.concat("  :  "); }
    
      for (var i = 0; i < this.pageArray[pageIndex].panelArray.length; i++) {
                                                                                          pageListText = pageListText.concat(this.pageArray[pageIndex].panelArray[i]).concat(": [");
        if (GlobalPanelManager.isPanelActive(this.pageArray[pageIndex].panelArray[i]))  { pageListText = pageListText.concat("Active],  "); }
        else                                                                            { pageListText = pageListText.concat("Inactive],  "); }
      }
      console.log(pageListText);
    }
    pageMountChecker(pageName, mountCompleteCallBackRoutine, oldPageIndex) {
      if (mountCompleteCallBackRoutine == null || mountCompleteCallBackRoutine === undefined) {
        console.log("makePageActive() requires a call back routine");
        return false;
      }
      if ((this.currentActivePageIndex < 0 || this.currentActivePageIndex > this.pageArray.length -1) && this.currentActivePageIndex != null) {
        console.log("makePageActive() Something is seriously wrong with this.currentActivePageIndex[".concat(this.currentActivePageIndex.toString()()).concat("]"));
        return false;
      }
      if (oldPageIndex === -1)
      {
        console.log("makePageActive() Page[".concat(pageName).concat("] does not exist"));
        return false;
      }
      if (oldPageIndex === this.currentActivePageIndex)
      {
        console.log("makePageActive() Page[".concat(pageName).concat("] is already the active page"));
        return false;
      }
      return true;
    }
  }