// organization: "incentilock", appId: "d04bdae5-9dd8-43c0-abe8-9c18a7cc67f7"
//
//////////////////////////////
// Chat Bot from HelpCrunch //
//////////////////////////////
//
window.HelpCrunch = function() { window.HelpCrunch.q.push(arguments) };
class HelpCrunchManagerObj {

    constructor ()
    {
        this.helpCrunchIsCreated        = false;
        this.helpCrunchSource           = null;
        this.helpCrunchSettings         = null;
        this.initAndDataLoaded          = false;
        this.helpCrunchWidgetIsVisible  = false;
        this.helpCrunchIsOpen           = false;
        this.afterHelpCrunchLoad        = this.afterHelpCrunchLoad.bind(this);
        this.initializedAndDataLoaded   = this.initializedAndDataLoaded.bind(this);
        this.chatWindowOpenedCallBack   = this.chatWindowOpenedCallBack.bind(this);
        this.chatWindowClosedCallBack   = this.chatWindowClosedCallBack.bind(this);
        this.initializeHelpCrunch();
        this.setupHelpCrunch();
    }
    isHelpCrunchWidgetVisible() { return (this.helpCrunchWidgetIsVisible); }
    isHelpCrunchLoaded()        { return (this.initAndDataLoaded); }
    isHelpCrunchCreated()       { return (this.helpCrunchIsCreated && this.initAndDataLoaded); }
    setupHelpCrunch ()          {
        this.helpCrunchSettings = window.helpcrunchSettings;
        if (this.helpCrunchSettings === undefined) {
            console.log("**** ERROR **** HelpCrunchManager::setupHelpCrunch: this.helpCrunchSettings is undefined");
        }
        if( ! this.helpCrunchSettings || ! this.helpCrunchSettings.organization) { return; }

        this.helpCrunchSource   = 'https://'.concat(this.helpCrunchSettings.organization).concat(".widget.helpcrunch.com/");
        window.HelpCrunch.q     = [];

        if ( document.readyState === "complete" || this.helpCrunchSource.loadImmediately) { this.afterHelpCrunchLoad(); } 
        else 
            if(window.attachEvent)  { window.attachEvent('onload', this.afterHelpCrunchLoad) }
            else                    { window.addEventListener('load', this.afterHelpCrunchLoad, false) }
    }
    afterHelpCrunchLoad()   {
 
        if (document.querySelector("script[src=".concat('"').concat(this.helpCrunchSource).concat('"'))) { return; }

        var documentScript   = document.createElement('script');
        documentScript.async = 1;
        documentScript.type  ="text/javascript";
        documentScript.src   = this.helpCrunchSource;
        (document.body || document.head).appendChild(documentScript);

        this.hideHelpCrunch();

        this.helpCrunchIsCreated = true;
        window.HelpCrunch('onReady',        this.initializedAndDataLoaded);
        window.HelpCrunch('onChatClose',    this.chatWindowClosedCallBack);
        window.HelpCrunch('onChatOpen',     this.chatWindowOpenedCallBack);
    }
    initializeHelpCrunch ()     { window.helpcrunchSettings = { organization: "incentilock", appId: "d04bdae5-9dd8-43c0-abe8-9c18a7cc67f7" } }
    initializedAndDataLoaded()  { this.initAndDataLoaded = true; console.log("HelpCrunch chat loaded")}
    hideHelpCrunch()            { window.HelpCrunch('hideChatWidget');  this.helpCrunchWidgetIsVisible  = false; }
    showHelpCrunch()            { window.HelpCrunch('showChatWidget');  this.helpCrunchWidgetIsVisible  = true; }
    openHelpCrunch()            { window.HelpCrunch('openChat');        this.helpCrunchIsOpen           = true; }
    closeHelpCrunch()           { window.HelpCrunch('closeChat');       this.helpCrunchIsOpen           = false; }
    isChatWindowOpened()        { return(this.helpCrunchIsOpen); }
    chatWindowOpenedCallBack()  { this.helpCrunchIsOpen = true; }
    chatWindowClosedCallBack()  { this.helpCrunchIsOpen = false; }
    setDebugMode()              { localStorage.setItem("helpcrunchDebugMode", true); }
    unsetDebugMode()            { localStorage.removeItem("helpcrunchDebugMode"); }
}
const           HelpCrunchManager = new HelpCrunchManagerObj();
export default  HelpCrunchManager;