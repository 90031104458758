    //
    // Copyright © 2022 Gaddis Laboratories LLC as part of the GaddisLabs Core Javasript IDE
    // The Core IDE is Licensed to customers of GaddisLabs only by contract, and restricted for all others
    //
    import  React                               from    "react";
    import  { gsap }                            from    "gsap";
    import  GlobalTextBestFitManager            from    "../GaddisLabsCore/textBestFit.js";
    //
    // These are used and shared by the subordinate panels
    //
    class GLabsUtilities {

        constructor() {
            this.canvasForMeasuringTextWidth              = document.createElement("canvas");
            this.contextForMeasuringTextWidth             = this.canvasForMeasuringTextWidth.getContext("2d");
        }
        scalePictureInDivLeftJustified(photoElementName, DivElementName) {
            //
            // Let's adjust the Picture to fit and be centered then the testimonial text
            //
            const photo                 = document.getElementById(photoElementName);
            const photoDiv              = document.getElementById(DivElementName);
            if (photo == null) {
                console.log("GLabsUtilities.scalePictureInDivLeftJustified(".concat(photoElementName).concat(")-Photo object is null"), photo);
                return;
            }
            if (photoDiv == null) {
                console.log("GLabsUtilities.scalePictureInDivLeftJustified(".concat(photoElementName).concat(")-Photo container Div object is null"), photoDiv);
                return;
            }
            const photoDivBoundingRect  = photoDiv.getBoundingClientRect();
            //
            // Check if the bounding div has switched from landscape to portrait, switch from width "auto" to height "auto"
            //
            if (photoDivBoundingRect.width > photoDivBoundingRect.height) {
                gsap.set(photo, {left: "0%", height: photoDivBoundingRect.height - 2, width: "auto"});
            }
            else {
                gsap.set(photo, {left: "0%", width: photoDivBoundingRect.width - 2, height: "auto"});
            }
            var photoBoundingRect     = photo.getBoundingClientRect();
            if (photoBoundingRect.width > photoDivBoundingRect.width) {
                gsap.set(photo, { left: "0%", width: photoDivBoundingRect.width - 2, height: "auto"});
            }
        }
        centerPictureInDiv(photoElementName, DivElementName) {
            //
            // Let's adjust the Picture to fit and be centered, we do this in three stages to enforce strict synchrony of the parallel processing GSAP engine
            //
            const photo                 = document.getElementById(photoElementName);
            const photoDiv              = document.getElementById(DivElementName);
            if (photo == null) {
                console.log("GLabsUtilities.centerPictureInDiv(".concat(photoElementName).concat(")-Photo object is null"), photo);
                return;
            }
            if (photoDiv == null) {
                console.log("GLabsUtilities.centerPictureInDiv(".concat(photoElementName).concat(")-Photo container Div object is null"), photoDiv);
                return;
            }
            const photoDivBoundingRect  = photoDiv.getBoundingClientRect();
            //
            // Check if the bounding div has switched from landscape to portrait, switch from width "auto" to height "auto"
            //
            if (photoDivBoundingRect.width > photoDivBoundingRect.height) {
                gsap.set(photo, {height: photoDivBoundingRect.height - 2, width: "auto", onComplete: ()=>{ this.firstPhotoStageComplete(photo, photoDivBoundingRect); }});
            }
            else {
                gsap.set(photo, {width: photoDivBoundingRect.width - 2, height: "auto", onComplete: ()=>{ this.firstPhotoStageComplete(photo, photoDivBoundingRect); }});
            }
        }
        firstPhotoStageComplete(photo, photoDivBoundingRect) {
            var photoBoundingRect     = photo.getBoundingClientRect();
            if (photoBoundingRect.width > photoDivBoundingRect.width) {
                gsap.set(photo, {width: photoDivBoundingRect.width - 2, height: "auto", onComplete: ()=>{ this.centerThePicInDiv(photo, photoDivBoundingRect); }});
            }
            else { this.centerThePicInDiv(photo, photoDivBoundingRect); }
        }
        centerThePicInDiv(photo, photoDivBoundingRect) {
            const photoBoundingRect     = photo.getBoundingClientRect();
            gsap.set(photo, {   left: (photoDivBoundingRect.width - photoBoundingRect.width)/2, 
                                top:  (photoDivBoundingRect.height - photoBoundingRect.height)/2});
        }
        stringToHex(stringToConvert) {
            var returnString = "";
            for (var i = 0; i < stringToConvert.length; ++i) {
              var byte = stringToConvert.charCodeAt(i);
              if(byte < 16) { byte = '0' + byte.toString(16); }
              returnString = returnString + byte.toString(16);
            }
            return returnString;
        } 
        getTextWidth(textToBeMeasured, fontOfText) {  // fontOfText = (ex.) "700 ".concat(fontsize.concat("px comfortaa"))
            this.contextForMeasuringTextWidth.font = fontOfText;
            var metrics = this.contextForMeasuringTextWidth.measureText(textToBeMeasured);
            return Math.ceil(metrics.width);
        }
        getTextHeight(textToBeMeasured, fontOfText) { // fontOfText = (ex.) "700 ".concat(fontsize.concat("px comfortaa"))
            this.contextForMeasuringTextWidth.font = fontOfText;
            var metrics = this.contextForMeasuringTextWidth.measureText(textToBeMeasured);
            return ({height: Math.ceil(metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent), heightDescent: metrics.emHeightDescent})
        }
        choseTheCorrectTextSize(newMessage, minFontSize, maxFontSize, fontWeight, font, containingDivWidth) {
            const increment = 2;
            for (var testFontSize = maxFontSize; testFontSize >= minFontSize; testFontSize -= increment) {
            if (this.willThisTextSizeFit(newMessage, testFontSize, fontWeight, font, containingDivWidth)) return(testFontSize);
            }
            return(minFontSize);
        }
        willThisTextSizeFit(testMessage, testFontSize, fontWeight, font, containingDivWidth) {
            var   messageFontSize       = testFontSize;
            var   testingFontComposite  = fontWeight.toString().concat(" ".concat(messageFontSize.toString()).concat("px ".concat(font)));

            var   meassuredLength       = this.getTextWidth(testMessage, testingFontComposite);

            if (meassuredLength > containingDivWidth) return(false);
            else                                      return(true);
        }
        resizeCopyRightMessage(ownersUniqueName) {
            if (document.getElementById(ownersUniqueName.concat("DivID")) == null) return; // Copyright must exist in DOM

            GlobalTextBestFitManager.findFontSizeForBestFit(ownersUniqueName.concat("DivID"));        
        }
        insertCopyRightJSX(ownersUniqueName, textColorOverride)   {

            if (document.getElementById(ownersUniqueName.concat("DivID")) != null) return; // Name must be unique

            GlobalTextBestFitManager.createTextBestFitObjectFor(ownersUniqueName.concat("DivID"), "comfortaa", "500", "14px", 1.0);

            const currentYear = new Date();

            var textColor         = "black";
            if (textColorOverride !== undefined) textColor = textColorOverride;
        
            const JSXCopyrightString =  
                                            <div  
                                                id={ownersUniqueName.concat("DivID")}
                                                className={ownersUniqueName.concat("Div")}
                                                key={ownersUniqueName+"Div-Key"}
                                                style={{
                                                    display:        'flex',
                                                    width:          "100%",
                                                    height:         "2%",
                                                    justifyContent: 'center',
                                                    padding:        '0px',
                                                    margin:         '0px',
                                                    bottom:         '0px',
                                                    zIndex:         0
                                                }}
                                                >
                                                <p 
                                                    key={ownersUniqueName.concat("Text-Key")}
                                                    style={{
                                                    position:       'absolute',
                                                    fontFamily:     'comfortaa, roboto, verdana, sans-serif',
                                                    color:          textColor, 
                                                    justifyContent: 'center',
                                                    padding:        '0px',
                                                    margin:         '0px',
                                                    bottom:         '1px',
                                                    zIndex:         1000
                                                    }}
                                                > 
                                                    ©&nbsp;2017-{currentYear.getFullYear()}&nbsp;<strong>Incenti</strong><em>Lock</em>,&nbsp;LLC.&nbsp;All Rights Reserved
                                                </p>
                                            </div>;
            return(JSXCopyrightString);
        }
        resizeWorkInProgressBanner(ownersUniqueName) {
            if (document.getElementById(ownersUniqueName.concat("DivID")) == null) return; // Banner must exist in DOM

            GlobalTextBestFitManager.findFontSizeForBestFit(ownersUniqueName.concat("DivID"));        
        }
        workInProgressBanner(leadInMessage, ownersUniqueName, textColorOverride)      { 

            if (document.getElementById(ownersUniqueName.concat("DivID")) != null) return; // Name must be unique

            GlobalTextBestFitManager.createTextBestFitObjectFor(ownersUniqueName.concat("DivID"), "comfortaa", "500", "14px", 1.0);

            var textColor         = "gray";
            if (textColorOverride !== undefined) textColor = textColorOverride;

            var leadInMessageText = "";
            if (leadInMessage !== undefined) leadInMessageText =leadInMessage

            const JSXString =  
                <div  
                id={ownersUniqueName.concat("DivID")}
                key={ownersUniqueName.concat("Div-Key")}
                style={{
                    position:       "absolute",
                    height:         "100%",
                    width:          "100%",
                    bottom:         "0%",
                    zIndex:         0,
                }}
                >
                <p  
                    id={ownersUniqueName.concat("Text-ID")}
                    key={ownersUniqueName.concat("Text-Key")}
                    style={{
                    color:          textColor,
                    fontFamily:     'comfortaa, roboto, verdana, sans-serif',
                    textAlign:      "center",
                    opacity:        "0.5",
                    }}
                > 
                    {leadInMessageText.concat(" Under Construction")}
                </p>
                </div>;

            return(JSXString);
        }
        darkenTheColorFromTextHexColor(colorInPoundHexFormat)
        {
            //
            // First let decompose it into its parts
            // 
            var subtractionAmount   = 7;
            var darkenedcolor       = "#";

            for (var i = 2; i < colorInPoundHexFormat.length; i=i+2)
            {
                var previousColorByte   = colorInPoundHexFormat.substr(i-1,1);
                var colorByte           = this.subtractFromHexValue(colorInPoundHexFormat.substr(i,1), subtractionAmount);
                if (colorByte === 0) {
                previousColorByte   = this.subtractFromHexValue(previousColorByte, 2);
                }

                darkenedcolor = darkenedcolor.concat(previousColorByte).concat(colorByte);
            }
            return(darkenedcolor);
        }
        subtractFromHexValue(hexCharacter, subtractionAmount) {

            var colorByte = hexCharacter;
            switch (colorByte)
            {
                case "A":
                case "a":
                    colorByte = 10-3;
                    break;
                case "B":
                case "b":
                    colorByte = 11-subtractionAmount;
                    break;
                case "C":
                case "c":
                    colorByte = 12-subtractionAmount;
                    break;
                case "D":
                case "d":
                    colorByte = (13-subtractionAmount).toString(16);
                    break;
                case "E":
                case "e":
                    colorByte = (14-subtractionAmount).toString(16);
                    break;
                case "F":
                case "f":
                    colorByte = (15-subtractionAmount).toString(16);
                    break;
                default:
                    colorByte = colorByte - subtractionAmount;
            }
            return ((colorByte < 0 ? 0 : colorByte));
        }
    } // End Class GLabsUtilities

    const GlabsUtilities = new GLabsUtilities();
    export default GlabsUtilities;