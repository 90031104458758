//
// This class replaces GSAP's scrollTrigger within the GaddisLabs Panel Management System.
//
//      Usage:  (1) Create an instance of InFocusTimelineManager and give it the "timeLineBuildMethod" (remember to bind it the the "this" operator)
//
//                  Example:    this.massagingPanelBuildTimeline    = this.massagingPanelBuildTimeline.bind(this);
//                              this.messagePanelInFocusManager     = new InFocusTimelineManager(this.massagingPanelBuildTimeline);
//
//              (2) Register the InFocusTimelineManager with the panel inFocus notification system
//
//                  Example:    GlobalPanelManager.addPanelInFocusWatcherToPanel(this.messagingPanelName, this.messagePanelInFocusManager.getInFocusManager());
//
//              (3) The panel manager will decide when the panel is in or out of focus, when it comes into focus or leaves focus it will call the this.inFocusHasChanged() of the InFocusTimelineManager
//
//              (4) The this.inFocusHasChanged() will decide what to do, it will call your timeline build method to build the timeline (in case the panel went out of the DOM). 
//                  Remember to always return the timeline var to the InFocusTimelineManager so we have access to the timeline. The timeline instance will change as it comes into and out of
//                  the DOM if the panel went inactive after being active.
//
//                  Example:    var messagingRunLoopTimeLine = gsap.timeline({ paused: true }); (Remember to pause the timeline so the InFocusTimelineManager is in charge)
//                              messagingRunLoopTimeLine.set(FirstXOutID, {blah blah blah...});
//                              return(messagingRunLoopTimeLine);
//
export default class InFocusTimelineManager {

    constructor(timeLineBuildMethod, inFocusRunLoop, resizePanelMethod, outOfFocusRunLoop) {
        this.DEBUG_ON                       = false;
        this.ownerName                      = "Unnamed";
        this.currentlyInFocus               = false;
        this.currentlyInDom                 = false;
        this.animationsAreSkipped           = false;                                                            // We can force animations off which makes them jump to their ends
        this.timeLineProgress               = 0.0;                                                              // We keep track of timelines progress here
        this.managedTimeLine                = null;                                                             // This can change as a timeline's elements come into and out of the DOM
        this.timeLineBuildMethod            = timeLineBuildMethod;                                              // This routine is where you build the timeline (start in paused mode)
        this.inFocusRunLoop                 = (inFocusRunLoop === undefined ? null : inFocusRunLoop);           // This routine will be run everytime the panel comes into focus
        this.resizePanelMethod              = (resizePanelMethod === undefined ? null : resizePanelMethod);     // We call this everytime the panel comes into focus
        this.outOfFocusRunLoop              = (outOfFocusRunLoop === undefined ? null : outOfFocusRunLoop);     // This routine will be run everytime the panel goes out of focus
        this.inFocusHasChanged              = this.inFocusHasChanged.bind(this);
    }
    setDEBUG_ON(optionalOwnerName)
    { 
        this.ownerName                      = (optionalOwnerName === undefined ? "Unnamed" : optionalOwnerName);
        this.DEBUG_ON = true;

        if (this.DEBUG_ON) {
            console.log("InFocusTimelineManager::setDEBUG_ON() turning DEBUG on for", this.ownerName);
        } 
    }
    setDEBUG_OFF()      { this.DEBUG_ON = false; }
    getInFocusManager() { return (this.inFocusHasChanged); }
    isPanelInFocus()    { return (this.currentlyInFocus); }
    showAnimations()    { 
        this.animationsAreSkipped   = false;
        this.timeLineProgress       = 0.0;
        if (this.managedTimeLine == null) this.managedTimeLine = this.timeLineBuildMethod(this.timeLineProgress);
        this.managedTimeLine.reverse("StartOfTimeLine");                     
        if (this.currentlyInFocus) this.managedTimeLine.play();
    }
    skipAnimations()    {
        this.animationsAreSkipped = true;
        if (this.managedTimeLine == null) this.managedTimeLine = this.timeLineBuildMethod(this.timeLineProgress);
        this.managedTimeLine.seek("EndOfTimeLine", false);
        this.timeLineProgress   = 1.0;
    }
    finishAnimations()    {
        if (this.managedTimeLine == null) this.managedTimeLine = this.timeLineBuildMethod(this.timeLineProgress);
        this.managedTimeLine.seek("EndOfTimeLine", false);
        this.timeLineProgress   = 1.0;
    }
    restartTimeLine()   {
        //
        // If the timeline was null then this is either the first time inFocus or the panel went out of the DOM and we have to rebuild it
        //
        if (this.managedTimeLine    == null) {
            this.managedTimeLine = this.timeLineBuildMethod(this.timeLineProgress);
            if (this.DEBUG_ON) {
                console.log("restartTimeLine() :: ".concat(this.ownerName).concat(" ** Rebuilt Managed Timeline **"));
            }
        }

        if (this.DEBUG_ON) {
            console.log("restartTimeLine() :: ".concat(this.ownerName).concat(": is restarting TimeLine, currently = ").concat((this.currentlyInFocus ? "InFocus, " : "OutOfFocus, ")).concat((this.currentlyInDom ? "InDOM, " : "OutOfDOM, ")).concat("Progress = ").concat(this.timeLineProgress.toString()));
        }

        if (this.resizePanelMethod  != null) { this.resizePanelMethod(); }

        this.timeLineProgress       = 0.0;
        this.managedTimeLine.reverse("StartOfTimeLine", false);
        if (this.currentlyInFocus) this.managedTimeLine.play();
    }
    killTimeLine()      
    {
        if (this.managedTimeLine != null) {
            this.timeLineProgress   = this.managedTimeLine.progress();
            this.managedTimeLine.kill();
            this.managedTimeLine    = null;         
            if (this.DEBUG_ON) {
                console.log("killTimeLine() :: ".concat(this.ownerName).concat(": is killing TimeLine, currently = ").concat((this.currentlyInFocus ? "InFocus, " : "OutOfFocus, ")).concat((this.currentlyInDom ? "InDOM, " : "OutOfDOM, ")).concat("Progress = ").concat(this.timeLineProgress.toString()));
            }
        }
    }
    //
    // inFocusFlag may have one of three states: (1) cameIntoFocus, (2) wentOutOfFocusButStillInDom, (3) wentOutOfFocusAndLeftDom. (See panel.js)
    //
    //      Whenever it goes out of focus we capture the animations current progress (even if it goes out of the DOM).
    //      We use this to restart the animation whenever it goes back into focus (and maybe on newly instantiated DOM objects).
    //      We restart the animation at the current progress point UNLESS that is overridden and the variable (restartOnFocus) is set.
    //      If restartOnFocus is set we only do this on the second inFocus === wentIntoFocus, not the first instance.
    //
    inFocusHasChanged(inFocusFlag) {
        //
        // Optional methods on resize or runLoop--these run first
        //
        if (this.resizePanelMethod  != null) { this.resizePanelMethod(); }
        //
        // If the timeline was null then this is either the first time inFocus or the panel went out of the DOM and we have to rebuild it
        //
        if (this.managedTimeLine == null) {
            this.managedTimeLine = this.timeLineBuildMethod(this.timeLineProgress);
            if (this.DEBUG_ON) {
                console.log("inFocusHasChanged() ::".concat(this.ownerName).concat(" ** Rebuilt Managed Timeline **"));
            }
        }
        //
        // If it came into focus then we want to start, restart at last saved label point, or if seen to the end, seek to the end (don't reshow)
        //
        switch(inFocusFlag) {

            case    "cameIntoFocus":

                if (this.DEBUG_ON) {
                    console.log("inFocusHasChanged() :: ".concat(this.ownerName).concat(": previous State = ").concat((this.currentlyInFocus ? "InFocus, " : "OutOfFocus, ")).concat((this.currentlyInDom ? "InDOM, " : "OutOfDOM, ")).concat("now ").concat(inFocusFlag).concat(", Progress = ").concat(this.managedTimeLine.progress().toString()));
                }
                this.currentlyInFocus   = true;
                this.currentlyInDom     = true;
                //
                // Optional method on in focus, run before
                //
                if (this.inFocusRunLoop != null) this.inFocusRunLoop();
                //
                // Now let's manage the timeline
                //
                if (this.timeLineProgress >= 1.0) {
                    this.managedTimeLine.progress(1.0, false); 
                }
                else {
                    if (this.timeLineProgress === 0.0)  { this.managedTimeLine.play(); }
                    else                                { this.managedTimeLine.progress(this.timeLineProgress, true); this.managedTimeLine.resume(); }
                }

                break;

            case    "wentOutOfFocusButStillInDom":

                if (this.DEBUG_ON) {
                    console.log("inFocusHasChanged() :: ".concat(this.ownerName).concat(": previous State = ").concat((this.currentlyInFocus ? "InFocus, " : "OutOfFocus, ")).concat((this.currentlyInDom ? "InDOM, " : "OutOfDOM, ")).concat("now ").concat(inFocusFlag).concat(", Progress = ").concat(this.managedTimeLine.progress().toString()));
                }

                this.currentlyInFocus   = false;
                this.currentlyInDom     = true;

                if (this.managedTimeLine != null) {

                    if (this.managedTimeLine.progress() < 1.0) {
                        this.managedTimeLine.pause();
                    }
                }
                //
                // Optional method on out of focus, run last
                //
                if (this.outOfFocusRunLoop != null) this.outOfFocusRunLoop();

                break;

            case    "wentOutOfFocusAndLeftDom":

                this.currentlyInFocus   = false;
                this.currentlyInDom     = false;
                this.killTimeLine();
                break;
            default:
                console.log("inFocusHasChanged(inFocusFlag):: inFocusFlag = Unkown Result");
        }
    }
}   // end class InFocusGSAPTimelineManager