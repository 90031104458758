
import  React                       from  "react";
import  Container                   from  "react-bootstrap/Container";
import  GlobalPanelManager          from    "./panelManager.js";
import  { gsap }                    from  "gsap";

export default class  Panel  {
  
    constructor(panelName, pageName)
    {
      this.panelName                    = panelName;
      this.pageName                     = (pageName === undefined ? null : pageName);
      this.panelLabel                   = null;
      this.containingDivName            = null;
      this.panelIsActive                = false;
      this.panelState                   = "atRest"; // "inMotion" is the other state
      this.windowResizeNotifier         = [];
      this.windowInFocusNotifier        = [];
      this.isPanelInFocus               = false;
      this.notifyOnRemountMethod        = null;
      this.scrollUpTimeline             = null;
      this.scrollDownTimeline           = null;
      this.clickedOnScrollUpIndicator   = this.clickedOnScrollUpIndicator.bind(this);
      this.clickedOnScrollDownIndicator = this.clickedOnScrollDownIndicator.bind(this);
      //
      // CSS attributes when created and mounted but not active
      //
      this.position                     = "relative"
      this.top                          = "0px";
      this.left                         = "0px";
      this.panelHeight                  = 2;
      this.panelWidth                   = 10;
      this.panelBackgroundColor         = "none";    // Seashell = #FFF5EE
      this.panelBorder                  = null;
      this.opacity                      = 1.0;
      this.zIndex                       = 1;

      this.myPanelMainDiv               = null;
      this.myPanelMainContainerDiv      = null;
      this.DOMinsertions                = [];

      this.finishedPanelHeightMorph     = this.finishedPanelHeightMorph.bind(this);
    }
    getPageName() { return(this.pageName); }
    //
    ////////////////////////
    // Window resize stuff
    ////////////////////////
    //
    registerWindowResizeNotifyHandler(notifyMethod) {
      if (notifyMethod != null && notifyMethod !== undefined)
        this.windowResizeNotifier.push(notifyMethod);
      }
    notifyPanelWindowResized(newSize, headerHeight) { 
      for (var i = 0; i < this.windowResizeNotifier.length; i++) {
        this.windowResizeNotifier[i](newSize);
      }
      this.centerScrollUpIndicator(headerHeight);
      this.centerScrollDownIndicator();
    }
    //
    ///////////////////
    // In Focus stuff
    ///////////////////
    //
    registerPanelInFocusNotifyHandler(notifyMethod) {
      if (notifyMethod != null && notifyMethod !== undefined)
      this.windowInFocusNotifier.push(notifyMethod);
    }
    checkIsThisPanelInFocus() {
      return(this.isPanelInFocus);
    }
    notifyPanelCameIntoFocus() {
      this.isPanelInFocus = true;
      for (var i = 0; i < this.windowInFocusNotifier.length; i++) {
        this.windowInFocusNotifier[i]("cameIntoFocus");
      }
    }
    notifyPanelWentOutOfFocusOnPage() {
      this.isPanelInFocus = false;
      for (var i = 0; i < this.windowInFocusNotifier.length; i++) {
        this.windowInFocusNotifier[i]("wentOutOfFocusButStillInDom");
      }
    }
    notifyPanelGoingOutOfFocusAndOutOfDom() {
      this.isPanelInFocus = false;
      for (var i = 0; i < this.windowInFocusNotifier.length; i++) {
        this.windowInFocusNotifier[i]("wentOutOfFocusAndLeftDom");
      }
    }
    //
    ////////////////////////
    // Setters and Getters
    ////////////////////////
    //
    setContainerDiv(containerName)                  { this.containingDivName = containerName; }
    setPanelIsInMotion()                            { this.panelState = "inMotion"}
    setPanelIsAtRest()                              { this.panelState = "atRest"}
    isPanelInMotion()                               { return(this.panelState === "inMotion" ? true : false); }
    getPanelName()                                  { return(this.panelName); }
    getPanelBackgroundColor()                       { return(this.panelBackgroundColor); }
    getPanelHeight()                                { return(this.panelHeight); }
    isPanelActive()                                 { return(this.panelIsActive); }
    makePanelInActive()                             { this.panelIsActive = false; }
    makePanelActive()                               { this.panelIsActive = true; }
    insertJSXString(JSXstring)                      { this.DOMinsertions.push(JSXstring); }
    setPanelBorderWidth(newWidth)                   { this.panelBorderWidth = newWidth; }
    panelIsMounted()                                { if (this.myPanelMainDiv != null) return (true); else return (false);}
    getPanelDivRef()                                { return(this.myPanelMainDiv);}
    getContainerDivRef()                            { return(this.myPanelMainContainerDiv); }
    getPanelID()                                    { return(this.panelName.concat("ID")); }
    setTopLeft(left, top)                           { this.left = left; this.top = top;  }
    setZIndex(newZIndex)                            { this.zIndex = newZIndex; }
    setOpacity(newOpacity)                          { this.opacity = newOpacity; }
    setPanelBorder(width, pattern, color)           { this.panelBorder = width.concat(" ", pattern, " ", color); }
    clearPanelBorder()                              { this.panelBorder = null;}
    setPanelPositionParameter(positioningType)      { this.position = positioningType; }
    setPanelBackgroundImage(imageRef, optionalCallBackOnComplete)
    {
      const   callBackOnComplete      = (optionalCallBackOnComplete != null && optionalCallBackOnComplete !== undefined ? optionalCallBackOnComplete : null)
      const   backgroundImageURL      = "url(".concat(imageRef).concat(")");
      gsap.set(this.myPanelMainDiv, {css: { backgroundImage:  backgroundImageURL, backgroundPosition:   "center center fixed",
                                            backgroundRepeat: "no-repeat",        backgroundAttachment: "fixed",
                                            backgroundSize:   "cover"}, onComplete: ()=> { if (callBackOnComplete != null) callBackOnComplete(); } })
      return(true);
    }
    morphPanelHeight(newHeight, setOrMorphFlag)     {

      if (this.isPanelInMotion() || this.myPanelMainDiv == null || this.panelHeight === newHeight) { this.panelHeight = newHeight; return; }

      if(setOrMorphFlag) {
        this.setPanelIsInMotion();
        gsap.set(this.myPanelMainDiv, {height: newHeight});
        this.finishedPanelHeightMorph(newHeight);
      }
      else
      {
        this.setPanelIsInMotion();
        gsap.to(this.myPanelMainDiv, {height: newHeight, duration: 1.0, onComplete: ()=> { this.finishedPanelHeightMorph(newHeight); }});
      }
    }
    finishedPanelHeightMorph(newHeight) {
      this.setPanelIsAtRest();
      this.panelHeight = newHeight;
      GlobalPanelManager.getScrollYTrackerManager().calculateVerticalMap();
    }
    getPanelColor() { return(this.panelBackgroundColor); }
    morphPanelColor(newColor, setOrMorphFlag)   { 
      if(this.myPanelMainDiv != null) {
        if(setOrMorphFlag) {
          gsap.set(this.myPanelMainDiv, {backgroundColor: newColor});
        }
        else {
          gsap.to(this.myPanelMainDiv, {backgroundColor: newColor, duration: 1.0});
        }
      }
      this.panelBackgroundColor = newColor;
    }
    labelPanel(currentIndex) {
      this.panelLabel = this.panelName.concat(" [").concat(currentIndex.toString()).concat("]");
      //
      // Push the label JSZ in the DOM insertions array so it's in front of everything else, it may interfere
      //
      const JSXLabelingString = 
        <div  
            id={this.panelName.concat('DEBUGLabel_ID')}
            key={this.panelName.concat('DEBUGLabel_Key')}
            style={{
                position:       "absolute",
                height:         "10%",
                width:          "90%",
                left:           "5%",
                top:            "10%",
            }}
        >
            <p  
                id={this.panelName.concat('DEBUGLabelText_ID')}
                key={this.panelName.concat('DEBUGLabelText_Key')}
                style={{
                  position:       'absolute',
                  fontWeight:     '700',
                  fontSize:       '50px',
                  fontFamily:     'comfortaa, roboto, verdana, sans-serif',
                  color:          '#FFFFFF', 
                  lineHeight:     "1.3",
                  margin:         "0px"
                }}>
                    {this.panelLabel}
            </p>
        </div>
      this.DOMinsertions.push(JSXLabelingString);
    }  
    renderMyPanel() {
      const position              =   this.position;
      const panelHeight           =   this.panelHeight + 'px';
      const panelLeft             =   this.left;
      const panelTop              =   this.top;
      const panelOpacity          =   (this.panelIsActive ? this.opacity : 0.0);
      const DIV_ZIndex            =   (this.panelIsActive ? this.zIndex : 0);
      const panelBackgroundColor  =   this.panelBackgroundColor;
      const compositeBorder       =   this.panelBorder;

      return (
        <Container fluid
          id={this.panelName.concat('ID')}
          key={this.panelName.concat('Key')}
          ref={div => this.myPanelMainDiv = div}
          style={{
            paddingLeft:      10,
            paddingRight:     0,
            paddingTop:       0,
            paddingBottom:    0,
            marginLeft:       0,
            marginRight:      0, 
            height:           panelHeight,
            backgroundColor:  panelBackgroundColor,
            top:              panelTop,
            left:             panelLeft,
            border:           compositeBorder,
            opacity:          panelOpacity,
            position:         position,
            zIndex:           DIV_ZIndex,
          }}>
            {this.DOMinsertions}
        </Container>
      );      
    }
    //
    // Scroll Up Indicator
    //
    getScrollTopIndicatorHeight() {
        const uniqueName = this.panelName.concat("ScrollUp");
        const ScrollUpIndicatorDiv    = document.getElementById("ScrollUpIndicatorDivID".concat(uniqueName));
        if( this.panelIsActive === false || ScrollUpIndicatorDiv == null) return(0);
        return(ScrollUpIndicatorDiv.getBoundingClientRect().height);
      }
      addScrollUpIndicatorToPanelAt(height, top) {
        const uniqueName = this.panelName.concat("ScrollUp");
        const scrollUpIndicatorSVGString = 
                                      <div
                                        id={"ScrollUpIndicatorDivID".concat(uniqueName)}
                                        key={"ScrollUpIndicatorDivKey".concat(uniqueName)}
                                          style={{ 
                                              position:       'absolute',
                                              height:         height,
                                              width:          100,
                                              opacity:        '1.0',
                                              padding:        '0px',
                                              margin:         '0px',
                                              top:            top,
                                              zIndex:         '1000' 
                                          }}
                                      >
                                        {ScrollDownLogo(uniqueName)}
                                      </div>
        this.insertJSXString(scrollUpIndicatorSVGString);
      }
      startScrollUpIndicatorAnimation() {
        if( this.panelIsActive === false ) return;
        
        const uniqueName              = this.panelName.concat("ScrollUp");
        const scrollUpText            = document.getElementById("ScrollDownText".concat(uniqueName));
        const ScrollUpArrow           = document.getElementById("ScrollDownArrow".concat(uniqueName));
        const ScrollUpIndicatorDiv    = document.getElementById("ScrollUpIndicatorDivID".concat(uniqueName));
  
        if (scrollUpText == null || ScrollUpArrow == null) return;
  
        const indicatorOpacity    = 0.4;
        this.centerScrollUpIndicator();
        gsap.set(scrollUpText,      {opacity: indicatorOpacity, y: +600})
        gsap.set(ScrollUpArrow,     {opacity: indicatorOpacity, transformOrigin: "50% 50%", rotate: "180", y: -100})
        this.scrollUpTimeline = gsap.timeline(
          {   paused: true,              
              repeat: -1
          })
          .to(ScrollUpArrow, {y: -300, ease: "Power0.easeInOut", duration: 1.0})
          .to(ScrollUpArrow, {y: -100, ease: "Power0.easeInOut", duration: 0.7})
           
        ScrollUpIndicatorDiv.addEventListener("click", this.clickedOnScrollUpIndicator);
      }
      clickedOnScrollUpIndicator() { GlobalPanelManager.getScrollYTrackerManager().scrollingDetected(-4); }
      stopScrollUpIndicatorAnimation() {
        if (this.panelIsActive) {
          const uniqueName              = this.panelName.concat("ScrollUp");
          const scrollUpText            = document.getElementById("ScrollDownText".concat(uniqueName));
          const ScrollUpArrow           = document.getElementById("ScrollDownArrow".concat(uniqueName));
          const ScrollUpIndicatorDiv    = document.getElementById("ScrollUpIndicatorDivID".concat(uniqueName));
          gsap.set([scrollUpText, ScrollUpArrow], {opacity: 0.0});
          ScrollUpIndicatorDiv.removeEventListener("click", this.clickedOnScrollUpIndicator); 
        }
        if (this.scrollUpTimeline != null) this.scrollUpTimeline.kill();
        this.scrollUpTimeline = null;
      }
      centerScrollUpIndicator(headerHeight) {
        const uniqueName                = this.panelName.concat("ScrollUp");
        const ScrollUpDiv               = document.getElementById("ScrollUpIndicatorDivID".concat(uniqueName));
        const scrollUpText              = document.getElementById("ScrollDownText".concat(uniqueName));
        const ScrollUpArrow             = document.getElementById("ScrollDownArrow".concat(uniqueName));
  
        if (scrollUpText == null || ScrollUpArrow == null || ScrollUpDiv == null) return;
                
        const scrollUpDivBoundingRect   = ScrollUpDiv.getBoundingClientRect();
        const newLeftCorner               = (this.myPanelMainDiv.offsetWidth/2) - (scrollUpDivBoundingRect.width/2);
        if (headerHeight !== undefined) {gsap.set(ScrollUpDiv, {left: newLeftCorner, top: headerHeight.toString().concat("px")});}
        else                            {gsap.set(ScrollUpDiv, {left: newLeftCorner});}
      }
      //
      // Scroll Down Indicator
      //
      addScrollDownIndicatorToPanelAt(height, bottom) {
        const uniqueName = this.panelName.concat("ScrollDown");
        const scrollDownIndicatorSVGString = 
                                      <div
                                        id={"ScrollDownIndicatorDivID".concat(uniqueName)}
                                        key={"ScrollDownIndicatorDivKey".concat(uniqueName)}
                                          style={{ 
                                              position:       'absolute',
                                              height:         height,
                                              width:          100,
                                              opacity:        '1.0',
                                              padding:        '0px',
                                              margin:         '0px',
                                              bottom:         bottom,
                                              zIndex:         '1000' 
                                          }}
                                      >
                                        {ScrollDownLogo(uniqueName)}
                                      </div>
        this.insertJSXString(scrollDownIndicatorSVGString);
      }
      startScrollDownIndicatorAnimation() {
        if( this.panelIsActive === false ) return;
  
        const uniqueName              = this.panelName.concat("ScrollDown");
        const scrollDownText          = document.getElementById("ScrollDownText".concat(uniqueName));
        const ScrollDownArrow         = document.getElementById("ScrollDownArrow".concat(uniqueName));
        const ScrollDownIndicatorDiv  = document.getElementById("ScrollDownIndicatorDivID".concat(uniqueName));
  
        if (scrollDownText == null || ScrollDownArrow == null) return;
  
        const indicatorOpacity    = 0.4;
        this.centerScrollDownIndicator();
        gsap.set(scrollDownText,      {opacity: indicatorOpacity, y: +100})
        gsap.set(ScrollDownArrow,     {opacity: indicatorOpacity, y: +100})
        this.scrollDownTimeline = gsap.timeline(
          {   paused: true,
              scrollTrigger:
              {
                  trigger: this.panelName,
                  markers: false,
                  pin: true,              
                  start: "top",       
                  end: "bottom",
              },              
              repeat: -1
          })
          .to(ScrollDownArrow, {y: +300, ease: "Power0.easeInOut", duration: 1.0})
          .to(ScrollDownArrow, {y: 100, ease: "Power0.easeInOut", duration: 0.7})
           
        ScrollDownIndicatorDiv.addEventListener("click", this.clickedOnScrollDownIndicator);
      }
      clickedOnScrollDownIndicator() { GlobalPanelManager.getScrollYTrackerManager().scrollingDetected(+4); }
      stopScrollDownIndicatorAnimation() {
        if (this.panelIsActive) {
          const uniqueName              = this.panelName.concat("ScrollDown");
          const scrollDownText          = document.getElementById("ScrollDownText".concat(uniqueName));
          const ScrollDownArrow         = document.getElementById("ScrollDownArrow".concat(uniqueName));
          const ScrollDownIndicatorDiv  = document.getElementById("ScrollDownIndicatorDivID".concat(uniqueName));
          gsap.set([scrollDownText, ScrollDownArrow], {opacity: 0.0});
          ScrollDownIndicatorDiv.removeEventListener("click", this.clickedOnScrollDownIndicator); 
        }
        if (this.scrollDownTimeline != null) this.scrollDownTimeline.kill();
        this.scrollDownTimeline = null;
      }
      centerScrollDownIndicator() {
        const uniqueName                    = this.panelName.concat("ScrollDown");
        const ScrollDownDiv                 = document.getElementById("ScrollDownIndicatorDivID".concat(uniqueName));
        const scrollDownText                = document.getElementById("ScrollDownText".concat(uniqueName));
        const ScrollDownArrow               = document.getElementById("ScrollDownArrow".concat(uniqueName));
  
        if (scrollDownText == null || ScrollDownArrow == null || ScrollDownDiv == null) return;
                
        const scrollDownDivBoundingRect   = ScrollDownDiv.getBoundingClientRect();
        const newLeftCorner               = (this.myPanelMainDiv.offsetWidth/2) - (scrollDownDivBoundingRect.width/2);
        gsap.set(ScrollDownDiv, {left: newLeftCorner});
      }
      setNotificationMethodForRemount(notifyOnRemount){ this.notifyOnRemountMethod = notifyOnRemount}
      getNotificationMethodForRemount()               { return(this.notifyOnRemountMethod)}
}
function ScrollDownLogo(uniqueNameExt) {  
  return (
    <svg
      id={"ScrollDownLogoSVGID".concat(uniqueNameExt)}
      key={"ScrollDownLogoSVGKey".concat(uniqueNameExt)}
      style={{ 
          position:       "absolute",
          height:         "100%",
          width:          "auto",
          opacity:        "1.0",
          padding:        "0px",
          margin:         "0px",
          bottom:         "0px",
          zIndex:         "10" 
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 758 1112"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeLinecap="round"
      strokeMiterlimit="2"
    >
      <g id={"ScrollDownTriangles".concat(uniqueNameExt)}  opacity="0.0" >
        <path id={"FirstTriangle".concat(uniqueNameExt)}  fill="#ebebeb"  opacity="0.0" d="M376.598,608.869l-170.494,-274.823l340.802,-0.115l-170.308,274.938Z"/>
        <path id={"SecondTriangle".concat(uniqueNameExt)} fill="#ebebeb"  opacity="0.0" d="M376.583,881.687l-143.634,-231.528l287.112,-0.096l-143.478,231.624Z"/>
        <path id={"ThirdTriangle".concat(uniqueNameExt)}  fill="#ebebeb"  opacity="0.0" d="M376.564,1111.58l-108.864,-175.482l217.611,-0.073l-108.747,175.555Z"/>
      </g>
      <g id={"ScrollDownArrow".concat(uniqueNameExt)}  opacity="0.0">
        <path fill="none"  stroke="#ebebeb"  strokeWidth="58.33px"  d="M689.305,362.793l-310.682,214.748l-310.827,-214.539"/>
      </g>
      <g id={"ScrollDownText".concat(uniqueNameExt)} opacity="0.0" >
        <path fill="#ebebeb"  d="M74.168,213.6c-11.378,-0 -21.911,-1.644 -31.6,-4.933c-9.689,-3.289 -18.089,-7.956 -25.2,-14c-7.111,-6.045 -12.534,-13.067 -16.267,-21.067c-1.422,-2.844 -1.467,-5.467 -0.133,-7.867c1.333,-2.4 3.6,-4.044 6.8,-4.933c2.489,-0.711 4.933,-0.444 7.333,0.8c2.4,1.244 4.222,3.111 5.467,5.6c2.666,5.156 6.578,9.778 11.733,13.867c5.156,4.089 11.289,7.244 18.4,9.466c7.111,2.223 14.933,3.334 23.467,3.334c9.422,-0 17.778,-1.556 25.066,-4.667c7.289,-3.111 13.023,-7.644 17.2,-13.6c4.178,-5.956 6.267,-13.2 6.267,-21.733c0,-10.845 -4,-20.178 -12,-28c-8,-7.823 -20.533,-12.8 -37.6,-14.934c-20.8,-2.489 -37.067,-8.933 -48.8,-19.333c-11.733,-10.4 -17.6,-23.156 -17.6,-38.267c0,-10.844 2.889,-20.266 8.667,-28.266c5.778,-8 13.733,-14.178 23.866,-18.534c10.134,-4.355 21.778,-6.533 34.934,-6.533c9.778,-0 18.666,1.556 26.666,4.667c8,3.111 14.978,7.2 20.934,12.266c5.955,5.067 10.8,10.623 14.533,16.667c1.778,2.844 2.356,5.6 1.733,8.267c-0.622,2.666 -2.177,4.711 -4.666,6.133c-2.667,1.244 -5.378,1.422 -8.134,0.533c-2.755,-0.889 -4.844,-2.577 -6.266,-5.066c-2.667,-4.089 -6,-7.956 -10,-11.6c-4,-3.645 -8.845,-6.534 -14.534,-8.667c-5.688,-2.133 -12.533,-3.289 -20.533,-3.467c-14.044,0 -25.333,2.978 -33.867,8.934c-8.533,5.955 -12.8,14.622 -12.8,26c0,6.044 1.6,11.6 4.8,16.666c3.2,5.067 8.489,9.423 15.867,13.067c7.378,3.644 17.289,6.356 29.733,8.133c22.223,3.2 38.712,10 49.467,20.4c10.756,10.4 16.133,23.956 16.133,40.667c0,9.6 -1.733,18.133 -5.2,25.6c-3.466,7.467 -8.355,13.733 -14.666,18.8c-6.311,5.067 -13.689,8.933 -22.134,11.6c-8.444,2.667 -17.466,4 -27.066,4Z"/>
        <path fill="#ebebeb"  d="M249.101,212.267c-14.044,-0 -26.533,-3.245 -37.467,-9.734c-10.933,-6.489 -19.555,-15.333 -25.866,-26.533c-6.311,-11.2 -9.467,-23.822 -9.467,-37.867c0,-14.222 3.022,-26.933 9.067,-38.133c6.044,-11.2 14.311,-20.044 24.8,-26.533c10.489,-6.489 22.578,-9.734 36.266,-9.734c10.845,0 20.845,2.134 30,6.4c9.156,4.267 17.2,10.667 24.134,19.2c1.955,2.134 2.666,4.4 2.133,6.8c-0.533,2.4 -2.044,4.489 -4.533,6.267c-1.956,1.422 -4.134,1.911 -6.534,1.467c-2.4,-0.445 -4.488,-1.734 -6.266,-3.867c-10.311,-11.733 -23.289,-17.6 -38.934,-17.6c-9.955,0 -18.711,2.4 -26.266,7.2c-7.556,4.8 -13.422,11.378 -17.6,19.733c-4.178,8.356 -6.267,17.956 -6.267,28.8c0,10.667 2.222,20.178 6.667,28.534c4.444,8.355 10.666,14.933 18.666,19.733c8,4.8 17.156,7.2 27.467,7.2c6.933,-0 13.289,-0.933 19.067,-2.8c5.778,-1.867 10.889,-4.756 15.333,-8.667c2.133,-1.777 4.356,-2.755 6.667,-2.933c2.311,-0.178 4.444,0.533 6.4,2.133c2.133,1.956 3.333,4.178 3.6,6.667c0.266,2.489 -0.578,4.622 -2.534,6.4c-12.977,11.911 -29.155,17.867 -48.533,17.867Z"/>
        <path fill="#ebebeb"  d="M351.501,121.067c0.533,-11.023 3.422,-20.889 8.667,-29.6c5.244,-8.711 12.089,-15.556 20.533,-20.534c8.445,-4.977 17.822,-7.466 28.133,-7.466c8.178,-0 14.489,1.155 18.934,3.466c4.444,2.311 6.133,5.689 5.066,10.134c-0.711,2.666 -1.911,4.444 -3.6,5.333c-1.688,0.889 -3.733,1.244 -6.133,1.067c-2.4,-0.178 -5.111,-0.356 -8.133,-0.534c-9.956,-0.889 -18.8,0.134 -26.534,3.067c-7.733,2.933 -13.866,7.422 -18.4,13.467c-4.533,6.044 -6.8,13.244 -6.8,21.6l-11.733,-0Zm1.6,89.866c-3.2,0 -5.689,-0.889 -7.467,-2.666c-1.777,-1.778 -2.666,-4.267 -2.666,-7.467l-0,-125.6c-0,-3.2 0.889,-5.689 2.666,-7.467c1.778,-1.777 4.267,-2.666 7.467,-2.666c3.2,-0 5.689,0.889 7.467,2.666c1.778,1.778 2.666,4.267 2.666,7.467l0,125.6c0,3.2 -0.888,5.689 -2.666,7.467c-1.778,1.777 -4.267,2.666 -7.467,2.666Z"/>
        <path fill="#ebebeb"  d="M520.834,212.267c-14.222,-0 -26.933,-3.2 -38.133,-9.6c-11.2,-6.4 -20,-15.2 -26.4,-26.4c-6.4,-11.2 -9.6,-23.911 -9.6,-38.134c0,-14.4 3.2,-27.2 9.6,-38.4c6.4,-11.2 15.2,-20 26.4,-26.4c11.2,-6.4 23.911,-9.6 38.133,-9.6c14.223,0 26.889,3.2 38,9.6c11.112,6.4 19.867,15.2 26.267,26.4c6.4,11.2 9.689,24 9.867,38.4c-0,14.223 -3.245,26.934 -9.734,38.134c-6.488,11.2 -15.288,20 -26.4,26.4c-11.111,6.4 -23.777,9.6 -38,9.6Zm0,-18.667c10.312,-0 19.556,-2.4 27.734,-7.2c8.178,-4.8 14.578,-11.378 19.2,-19.733c4.622,-8.356 6.933,-17.867 6.933,-28.534c0,-10.666 -2.311,-20.222 -6.933,-28.666c-4.622,-8.445 -11.022,-15.067 -19.2,-19.867c-8.178,-4.8 -17.422,-7.2 -27.734,-7.2c-10.311,0 -19.555,2.4 -27.733,7.2c-8.178,4.8 -14.622,11.422 -19.333,19.867c-4.711,8.444 -7.067,18 -7.067,28.666c0,10.667 2.356,20.178 7.067,28.534c4.711,8.355 11.155,14.933 19.333,19.733c8.178,4.8 17.422,7.2 27.733,7.2Z"/>
        <path fill="#ebebeb"  d="M666.434,210.933c-7.111,0 -13.466,-1.955 -19.066,-5.866c-5.6,-3.911 -9.956,-9.334 -13.067,-16.267c-3.111,-6.933 -4.667,-14.933 -4.667,-24l0,-152c0,-3.022 0.934,-5.467 2.8,-7.333c1.867,-1.867 4.312,-2.8 7.334,-2.8c3.022,-0 5.466,0.933 7.333,2.8c1.867,1.866 2.8,4.311 2.8,7.333l0,152c0,7.644 1.556,13.867 4.667,18.667c3.111,4.8 7.066,7.2 11.866,7.2l6.667,-0c2.667,-0 4.8,0.933 6.4,2.8c1.6,1.866 2.4,4.311 2.4,7.333c0,3.022 -1.111,5.467 -3.333,7.333c-2.222,1.867 -5.111,2.8 -8.667,2.8l-3.467,0Z"/>
        <path fill="#ebebeb"  d="M741.634,210.933c-7.111,0 -13.466,-1.955 -19.066,-5.866c-5.6,-3.911 -9.956,-9.334 -13.067,-16.267c-3.111,-6.933 -4.667,-14.933 -4.667,-24l0,-152c0,-3.022 0.934,-5.467 2.8,-7.333c1.867,-1.867 4.312,-2.8 7.334,-2.8c3.022,-0 5.466,0.933 7.333,2.8c1.867,1.866 2.8,4.311 2.8,7.333l0,152c0,7.644 1.556,13.867 4.667,18.667c3.111,4.8 7.066,7.2 11.866,7.2l6.667,-0c2.667,-0 4.8,0.933 6.4,2.8c1.6,1.866 2.4,4.311 2.4,7.333c0,3.022 -1.111,5.467 -3.333,7.333c-2.222,1.867 -5.111,2.8 -8.667,2.8l-3.467,0Z"/>
      </g>
    </svg>
  );
}