import gsap from "gsap";

class TextBestFitManager  {

    constructor() {
        //
        // We create *one* TextBestFitHTMLInstance for each text <div>/<p> we are asked to manage and store it here which we look up with
        this.TextBoxInstanceArray       = [];
        this.fontPrecaluteSizes         = {};
        //
        // Text width & height measuring in canvas support
        //
        this.canvasForMeasuringTextWidth              = document.createElement("canvas");
        this.contextForMeasuringTextWidth             = this.canvasForMeasuringTextWidth.getContext("2d");
    }
    //
    // Access Methods
    //
    createTextBestFitObjectFor(DivIDName, font, fontWeight, initialFontSize, lineHeight, justifiedContentOverride, tightDivOverride) { // first four arguments required
        //
        // The TextBestFitHTMLInstance object's <div> container need not be mounted when it is created but must be for operational effect
        //
        if (DivIDName === undefined || font === undefined || fontWeight === undefined || initialFontSize === undefined)
        {
            console.log("TextBestFitManager::createTextBestFitObjectFor(): First four arguments are required");
            return;
        }
        var justifiedContent = "center";
        if (justifiedContentOverride !== undefined) justifiedContent = justifiedContentOverride;

        const textManager = new TextBestFitHTMLInstance(DivIDName, font, fontWeight, initialFontSize, lineHeight, justifiedContent, tightDivOverride);
        this.TextBoxInstanceArray.push(textManager);
    }
    getTextBestFitObjectByName(DivIDName) {
        for (var indexOfMatch = 0; indexOfMatch < this.TextBoxInstanceArray.length; indexOfMatch++)
        {
          if (this.TextBoxInstanceArray[indexOfMatch].getTextBestFitDivIDName() ===  DivIDName) { 
            return (this.TextBoxInstanceArray[indexOfMatch]);
          }
        }
        return (null);
    }
    getTextWidth(textToBeMeasured, font, fontWeight, messageFontSize) {
        this.contextForMeasuringTextWidth.font = fontWeight.concat(" ").concat(messageFontSize).concat(" ").concat(font);
        var metrics = this.contextForMeasuringTextWidth.measureText(textToBeMeasured);
        return Math.ceil(metrics.width);
    }
    getTextHeight(textToBeMeasured, font, fontWeight, messageFontSize) {
 
        this.contextForMeasuringTextWidth.font = fontWeight.concat(" ").concat(messageFontSize).concat(" ").concat(font);
        var metrics = this.contextForMeasuringTextWidth.measureText(textToBeMeasured);
        return (Math.ceil(metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent));
    }
    findFontSizeForBestFit(DivIDName) {
        const bestFitObject = this.getTextBestFitObjectByName(DivIDName);
        if (bestFitObject == null) {
            console.log("TextBestFitManager::findFontSizeForBestFit(): You must first create a manager object (use createTextBestFitObjectFor()) for", DivIDName);
            return(false);
        }
        return(bestFitObject.findFontSizeForBestFit());
    }
    findBestFontSizeBeforeMounting(DivIDName, width, height, textString) {
        const bestFitObject = this.getTextBestFitObjectByName(DivIDName);
        if (bestFitObject == null) {
            console.log("TextBestFitManager::findFontSizeForBestFit(): You must first create a manager object (use createTextBestFitObjectFor()) for", DivIDName);
            return("6px");
        }
        return(bestFitObject.findBestFontSizeBeforeMounting(width, height, textString));
    }
} // End class TextBestFitManager

class TextBestFitHTMLInstance {

    constructor(DivIDName, fontarg, fontWeightarg, initialFontSize, lineHeightarg, justifiedContentOverride, tightDivOverride) {
        this.DivIDNameText              = DivIDName;
        this.tightFitInDiv              = (tightDivOverride === undefined ? false : tightDivOverride);
        this.font                       = fontarg;
        this.currentFontSize            = initialFontSize;
        this.minimumFontSize            = 6;
        this.maximumFontSize            = 200;                                              
        this.currentFontWeight          = fontWeightarg;
        this.lineHeight                 = lineHeightarg;
        this.justifiedContent           = justifiedContentOverride;
        this.textWithSpecialCharacters  = null;
        this.lineArrayGivenNewlines     = [];
        this.lineArrayOfWords           = [];
    }
    getTextBestFitDivIDName() { return(this.DivIDNameText); }
    findFontSizeForBestFit()  {
        const textObjectRef = document.getElementById(this.DivIDNameText);

        //console.log(this.DivIDNameText.concat("-BoundaryRect:"), textObjectRef.getBoundingClientRect(), textObjectRef);

        if (textObjectRef == null) { return(false); } // It's okay to call this if unmounted
        //
        // Clear out any previous work
        //
        this.lineArrayGivenNewlines = [];
        this.lineArrayOfWords       = [];
        //
        // Let's get a clean set of lines and convert it into a string of words that we can use to construct our lines
        //
        this.lineArrayGivenNewlines = textObjectRef.innerText.split('\n');

        //console.log("findFontSizeForBestFit()", this.lineArrayGivenNewlines);

        for (var i = 0; i < this.lineArrayGivenNewlines.length; i++) { this.lineArrayOfWords.push(this.lineArrayGivenNewlines[i].trim().split(" ")); }
        const   extractedFontSize       = this.currentFontSize.replace("px", "");
        var     fontSizeNumeric         = parseInt(extractedFontSize, 10);

        const   textObjectRefBoundaryRect = textObjectRef.getBoundingClientRect();
        const   availableWidth = textObjectRefBoundaryRect.width * .96;
        const   availableHeight = textObjectRefBoundaryRect.height * .95;
        var     capacityResponse        = this.calculateCharacterCapacity(availableWidth, availableHeight);

        switch (capacityResponse.MeasuredFont)
        {
            case    "Increase fontSize": 
                    for (i = fontSizeNumeric + 1; i <= this.maximumFontSize; i++)
                    {
                        this.currentFontSize    = i.toString().concat("px");

                        if (i === this.maximumFontSize)  { // Can't go no further
                            this.setFontAndCenter(textObjectRef);
                            break;
                        }

                        capacityResponse        = this.calculateCharacterCapacity(availableWidth, availableHeight);
                        if (capacityResponse.MeasuredFont === "Decease fontSize") {
                            this.currentFontSize = (i-1).toString().concat("px");
                            this.setFontAndCenter(textObjectRef);
                            break;
                        }
                        if (capacityResponse.MeasuredFont === "Use this fontSize")
                        {
                            this.setFontAndCenter(textObjectRef);
                            break;
                        }
                    }
                    break;

            case    "Decease fontSize":
                    for (i = fontSizeNumeric - 1; i >= this.minimumFontSize; i--)
                    {
                        this.currentFontSize    = i.toString().concat("px");

                        if (i === this.minimumFontSize)  { // Can't go no further
                            this.setFontAndCenter(textObjectRef);
                            break;
                        }
                        capacityResponse        = this.calculateCharacterCapacity(availableWidth, availableHeight);

                        if (capacityResponse.MeasuredFont === "Increase fontSize") {
                            this.currentFontSize = (i+1).toString().concat("px");
                            this.setFontAndCenter(textObjectRef);
                            break;
                        }
                        if (capacityResponse.MeasuredFont === "Use this fontSize")
                        {
                            this.setFontAndCenter(textObjectRef);
                            break;
                        }
                    }
                    break;

            case    "Use this fontSize":
            default:
                    this.setFontAndCenter(textObjectRef);
                    break;
        }
        //console.log("findFontSizeForBestFit(fontSize = ".concat(this.currentFontSize).concat(" ) for size of [").concat(availableWidth.toString()).concat(", ").concat(availableHeight.toString()).concat("]"), this.lineArrayGivenNewlines);
        return(this.currentFontSize);
    }
    setFontAndCenter(textObjectRef) {
        //console.log("Setting fontSize To: ", this.currentFontSize, textObjectRef, this.justifiedContent);
        gsap.set(textObjectRef, {fontSize: this.currentFontSize, font: this.font, fontWeight: this.currentFontWeight, lineHeight: this.lineHeight,
                                 display: "flex", justifyContent: this.justifiedContent, alignItems: "center"});
    }
    calculateCharacterCapacity(availableWidth, availableHeight) {
        //
        // We start with the first line and start laying out each word (using it's actual length) add a space character and then 
        // continue adding words until we need a line break (if we need a line break). We do that till we exhaust the original
        // input line. When that is doe we process the next input line's words in the same manner until we have processed all of the
        // input lines. We keep track of the constructed lines in an array that we then add up the height of each line to see if
        // we can fit it all in.
        //
        // Create the line array from the raw input
        //      Note: this.lineArrayOfWords is an array of arrays of words
        //
        var     composedLineLengthInPX = 0;
        var     composedLine = "";
        var     composedLineArray = [];
        for (var i = 0; i < this.lineArrayOfWords.length; i++)
        {
            for (var j = 0; j < this.lineArrayOfWords[i].length; j++)
            {
                var wordlength = GlobalTextBestFitManager.getTextWidth( " ".concat(this.lineArrayOfWords[i][j]),
                                                                        this.font, this.currentFontWeight, this.currentFontSize);
                if (composedLineLengthInPX + wordlength < availableWidth)
                { // Add to the existing line
                    composedLineLengthInPX = composedLineLengthInPX + wordlength;
                    composedLine = composedLine.concat(" ").concat(this.lineArrayOfWords[i][j]);
                }
                else
                { // Create a new line
                    composedLineArray.push(composedLine);
                    composedLine = "";
                    composedLine = composedLine.concat(" ").concat(this.lineArrayOfWords[i][j]);
                    composedLineLengthInPX = wordlength;
                }
                if (j === this.lineArrayOfWords[i].length - 1 && composedLine !== "")
                { // Put the last fragment(s) of the word array line (if it exists) into the composited line array as a line
                    composedLineArray.push(composedLine);
                    composedLineLengthInPX = 0;
                    composedLine = "";
                }
            }
            if (i === this.lineArrayOfWords.length - 1 &&
                composedLineLengthInPX !== 0)
            { // put the last line (if it exists) into array as a line
                composedLineArray.push(composedLine);
                composedLineLengthInPX = 0;
                composedLine = "";
            }
            //
            // We finished with processing that line, move to the next and create a new line
            //
            composedLineLengthInPX = 0;
            composedLine = "";
        }
        //
        // Now that we have the line array with proper width per line let's check the composite height against the text div
        //
        var compositeHeight     = 0;
        var lineHeight          = 0;
        var tallestLineHeight   = 0;
        var lineWidth           = 0;
        var widestLineWidth     = 0;
        for (var k = 0; k < composedLineArray.length; k++)
        {
            lineHeight  = GlobalTextBestFitManager.getTextHeight(composedLineArray[k], this.font, this.currentFontWeight, this.currentFontSize) * this.lineHeight;
            lineWidth   = GlobalTextBestFitManager.getTextWidth(composedLineArray[k], this.font, this.currentFontWeight, this.currentFontSize);
            if (lineHeight  > tallestLineHeight)    tallestLineHeight   = lineHeight;
            if (lineWidth   > widestLineWidth)      widestLineWidth     = lineWidth;
            compositeHeight = compositeHeight + lineHeight;
        }
        if (compositeHeight > availableHeight || widestLineWidth > availableWidth)  return({"MeasuredFont" : "Decease fontSize"});
        if (this.tightFitInDiv) {
            if (compositeHeight < availableHeight - tallestLineHeight)              return({"MeasuredFont" : "Increase fontSize"});
        }
        else {
            if (compositeHeight < availableHeight)                                  return({"MeasuredFont" : "Increase fontSize"});
        }
        return({"MeasuredFont" : "Use this fontSize"});
    }
    findBestFontSizeBeforeMounting(width, height, textString)  {
        //
        // Clear out any previous work
        //
        this.lineArrayGivenNewlines = [];
        this.lineArrayOfWords       = [];
        //
        // Let's get a clean set of lines and convert it into a string of words that we can use to construct our lines
        //
        this.lineArrayGivenNewlines = textString.split('\n');

        for (var i = 0; i < this.lineArrayGivenNewlines.length; i++) { this.lineArrayOfWords.push(this.lineArrayGivenNewlines[i].trim().split(" ")); }
        const   extractedFontSize       = this.currentFontSize.replace("px", "");
        var     fontSizeNumeric         = parseInt(extractedFontSize, 10);
        var     capacityResponse        = this.calculateCharacterCapacity(width, height);

        switch (capacityResponse.MeasuredFont)
        {
            case    "Increase fontSize": 
                    for (i = fontSizeNumeric + 1; i <= this.maximumFontSize; i++)
                    {
                        this.currentFontSize    = i.toString().concat("px");

                        if (i === this.maximumFontSize)  { // Can't go no further
                            break;
                        }

                        capacityResponse        = this.calculateCharacterCapacity(width, height);
                        if (capacityResponse.MeasuredFont === "Decease fontSize") {
                            this.currentFontSize = (i-1).toString().concat("px");
                            break;
                        }
                        if (capacityResponse.MeasuredFont === "Use this fontSize")
                        {
                            break;
                        }
                    }
                    break;

            case    "Decease fontSize":
                    for (i = fontSizeNumeric - 1; i >= this.minimumFontSize; i--)
                    {
                        this.currentFontSize    = i.toString().concat("px");

                        if (i === this.minimumFontSize)  { // Can't go no further
                            break;
                        }
                        capacityResponse        = this.calculateCharacterCapacity(width, height);

                        if (capacityResponse.MeasuredFont === "Increase fontSize") {
                            this.currentFontSize = (i+1).toString().concat("px");
                            break;
                        }
                        if (capacityResponse.MeasuredFont === "Use this fontSize")
                        {
                            break;
                        }
                    }
                    break;

            case    "Use this fontSize":
            default:
                    break;
        }
        //console.log("findBestFontSizeBeforeMounting(fontSize = ".concat(this.currentFontSize).concat(" ) for size of [").concat(width.toString()).concat(", ").concat(height.toString()).concat("]"), this.lineArrayGivenNewlines);
        return(this.currentFontSize);
    }
} // end TextBestFitHTMLInstance

const GlobalTextBestFitManager = new TextBestFitManager();
export default GlobalTextBestFitManager;
