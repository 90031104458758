import  React                     from  'react';
import  ReactDOM                  from  'react-dom';
import                                  './index.css';
import  VisiblePageManager        from  './visiblePageManager.js';
import                                  'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <React.StrictMode>
    <VisiblePageManager />
  </React.StrictMode>,
  document.getElementById('root')
);