//
// Copyright © 2021 Gaddis Laboratories LLC as part of the GaddisLabs Core Javascript IDE
// The Core IDE is Licensed to customers of GaddisLabs only by contract, and restricted for all others
//
import  React                       from  "react";
import  { gsap }                    from  "gsap";
import  Panel                       from  "./panel.js";
import  pageManager                 from  "./pageManager.js";
import  GlobalWindowManager         from  "./windowManager.js";
import  GlobalHeaderManager         from  "../headerPanelManager.js";
import                                    "../index.css";
import  { ScrollToPlugin }          from  "gsap/ScrollToPlugin";
import  { CustomEase }              from  "gsap/CustomEase";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);

class  panelNavigator  {
  constructor() {
    this.panelNavigatorIsActive       = false;
    this.hidePanelNavigatorFlag       = false;
    this.currentHighlightedNav        = 0;
    this.panelIndicatorArray          = [];
    this.tabColor                     = "#3fa9f5";
    this.onClickHandler               = this.onClickHandler.bind(this);
    this.jumpToPanelComplete          = this.jumpToPanelComplete.bind(this);
  }
  panelListHasChanged(activePanelArrayList) {
    this.panelIndicatorArray = [];
    for (var i = 0; i < activePanelArrayList.length; i++) {
      this.panelIndicatorArray.push(activePanelArrayList[i].getPanelName());
    }
  }
  changeTabColor(newColor)  { if (newColor !== undefined) this.tabColor = newColor; }
  startNavigator(panelName) { this.panelNavigatorIsActive = true; this.advanceNavigatorTo(panelName); }
  stopNavigator()           { this.panelNavigatorIsActive = false; }
  isNavigatorActive()       { return(this.panelNavigatorIsActive); }
  hidePanelNavigator()      { this.hidePanelNavigatorFlag = true; GlobalPanelManager.forceRedrawOnDOM(); }
  showPanelNavigator()      { this.hidePanelNavigatorFlag = false; GlobalPanelManager.forceRedrawOnDOM(); }
  advanceNavigatorTo(panelName) {
    if ( ! this.panelNavigatorIsActive ) return null;
    for (var i = 0; i < this.panelIndicatorArray.length; i++) {
      if (this.panelIndicatorArray[i] === panelName)  {this.currentHighlightedNav = i}
    }
    GlobalPanelManager.forceRedrawOnDOM();
  }
  onClickHandler(event) {

    if (GlobalPanelManager.isPanelScrolling()) return;
    
    for (var i = 0; i < this.panelIndicatorArray.length; i++) {
      if (event.target.farthestViewportElement.id.toString() === this.panelIndicatorArray[i].concat(i.toString()).concat("ID")) {
        //
        // To prevent race condition on selector and panel readiness we require that the panel be fully mounted
        //
        var panelObj = GlobalPanelManager.getPanelByName(this.panelIndicatorArray[i]);
        if (panelObj == null)             return;
        if (! panelObj.isPanelActive())   return;
        if (! panelObj.panelIsMounted())  return;        

        GlobalPanelManager.putPanelToTopOfPage(this.panelIndicatorArray[i], this.jumpToPanelComplete);
      }
    }
  }
  jumpToPanelComplete() {}
  render(headerHeight) {
    if (    this.hidePanelNavigatorFlag )           return null;
    if ( !  this.panelNavigatorIsActive )           return null;
    if (    this.panelIndicatorArray.length === 1 ) return null;
    
    const minWidth                = (window.innerWidth * 0.01 < 18 ? "18" : window.innerWidth * 0.01);
    const minWidthInPX            = minWidth.toString().concat("px");

    var renderingIndicatorRows = [];
    for (var i = 0; i < this.panelIndicatorArray.length; i++) {
      renderingIndicatorRows.push(
        <div
          key={"NavigatorLiKey".concat(i.toString())}
          style={{
              position:         "relative",
              listStyleType:    "none",
          }}
        >
          {NavigatorButton( this.panelIndicatorArray[i].concat(i.toString()),
                            (i === this.currentHighlightedNav ? this.tabColor : "#443737"),     
                            "#A1A1A1",
                            minWidthInPX,
                            this.onClickHandler)}   
        </div>
      );
    }
    const heightOfNavigatorInPX   = (renderingIndicatorRows.length * minWidth);
    const topOfNavigator          = ((window.innerHeight - headerHeight - heightOfNavigatorInPX)/2).toString().concat("px");
    const navigatorJSXString      = 
      <div
        id="NavigatorDiv"
        key="NavigatorKey"
        style={{
            position:       "fixed",
            height:         heightOfNavigatorInPX,
            width:          minWidthInPX,
            fontSize:       0,  // Setting this to zero gets rid of the space gap between vertical <div>'s
            margin:         0,
            padding:        0,
            right:          "1%",
            opacity:        "1.0",
            top:            topOfNavigator,
            zIndex:         "5000",
        }}
      >
        {renderingIndicatorRows}
      </div>;
    return (navigatorJSXString);
  }
}
function NavigatorButton(uniqueNameExt, fillColor, borderColor, widthAndHeightInPX, onClickHandler) {
  var fillColorOverride   = (fillColor          === undefined ? "#443737" : fillColor);
  var borderColorOverride = (borderColor        === undefined ? "#A1A1A1" : borderColor);
  var widthAndHeight      = (widthAndHeightInPX === undefined ? "18px"    : widthAndHeightInPX);
  return(
    <svg
      id={uniqueNameExt.concat("ID")}
      key={uniqueNameExt.concat("Key")}
      onClick={(event)=>{onClickHandler(event);}}
      style={{ 
        width:          widthAndHeight,
        height:         widthAndHeight,
        opacity:        '1.0',
        padding:        '0px',
        margin:         '0px',
        top:            '0px',
        left:           '0px',
        zIndex:         '5001',
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1162 1162"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeLinecap="round"
      strokeMiterlimit="1.5"
    >
      <rect x="27.778" y="27.778" width="1105.71" height="1105.71" fill={fillColorOverride} strokeOpacity="0.4" stroke={borderColorOverride} strokeWidth="55.56px"/>
    </svg>
  );
}
class  scrollYTracker  {
  constructor() {
    this.activePanelArray             = null;
    this.scrollingDisabledFlag        = false;
    this.panelVerticalMap             = [];
    this.lastRecordedScreenHeight     = window.innerHeight;
    this.calculateVerticalMap         = this.calculateVerticalMap.bind(this);
    this.jumpToPanelCompleted         = this.jumpToPanelCompleted.bind(this);
    GlobalWindowManager.registerWindowResizeHandler(this.calculateVerticalMap);
  }
  addedActiveAndMountedPanel(activePanelArray) {
    this.activePanelArray             = activePanelArray;
    this.calculateVerticalMap();
  }
  removedActiveAndMountedPanel(activePanelArray) {
    this.activePanelArray             = activePanelArray;
    this.calculateVerticalMap();
  }
  calculateVerticalMap() {
    if (this.activePanelArray == null)      return;
    if (this.activePanelArray.length <= 0)  return;
    this.panelVerticalMap = [];
    var nextPanelTop      = 0;
    for (var i = 0; i < this.activePanelArray.length; i++) {
      this.panelVerticalMap.push({
          panelName:      this.activePanelArray[i].getPanelName(),
          topOfPanel:     nextPanelTop,
          bottomOfPanel:  nextPanelTop + this.activePanelArray[i].getPanelHeight() - 1
      });
      nextPanelTop = nextPanelTop + this.activePanelArray[i].getPanelHeight();
    }
  }
  whichPanelIsThisScrollLocationIn(scrollY) {
    if (scrollY < 0) 
      return({panelName: this.panelVerticalMap[0].panelName, panelIndex: 0});
    if (scrollY > this.panelVerticalMap[this.panelVerticalMap.length-1].topOfPanel)
      return({panelName: this.panelVerticalMap[this.panelVerticalMap.length-1].panelName, panelIndex: this.panelVerticalMap.length-1});

    for (var i = 0; i < this.panelVerticalMap.length; i++) {
      if (scrollY >= this.panelVerticalMap[i].topOfPanel && scrollY <= this.panelVerticalMap[i].bottomOfPanel - 1)
        return ({panelName: this.panelVerticalMap[i].panelName, panelIndex: i});
    }
  }
  disableScrolling()    { this.scrollingDisabledFlag = true; }
  isScrollingDisabled() { return(this.scrollingDisabledFlag); }
  enableScrolling()     { this.scrollingDisabledFlag = false; }
  scrollingDetected(deltaY)
  {
    if (this.scrollingDisabledFlag) return;
    if (this.panelVerticalMap.length-1 <= 1) return; // Probably in a transitioning state
    if (deltaY===0) return;
    //
    // Mobile website often add or subtract header info as you touch scroll up or down, gotta fix that first
    //
    if (this.lastRecordedScreenHeight !== window.innerHeight && GlobalWindowManager.isThisMobileDevice()) {
      console.log("Mobile Window changed Height: ", this.lastRecordedScreenHeight, window.innerHeight);
      GlobalPanelManager.windowResized({width: window.innerWidth, height: window.innerHeight});
    }
    this.lastRecordedScreenHeight = window.innerHeight;
    //
    // First we check if the scroll is Up or Down, then we check if the scroll jumps multiple panels
    //
    const panelInViewAtScrollY  = this.whichPanelIsThisScrollLocationIn(window.scrollY);
    if (panelInViewAtScrollY == null || panelInViewAtScrollY === undefined) return;
    
    switch((deltaY > 0 ? false : true)) {
      //
      //////////////////////////////////
      case true:         // Scrolling UP
      //////////////////////////////////
      //
        if (panelInViewAtScrollY.panelIndex === 0) { // We are at the top
          GlobalPanelManager.putPanelToTopOfPage(this.panelVerticalMap[0].panelName, this.jumpToPanelCompleted);
          return;
        }
        GlobalPanelManager.putPanelToTopOfPage(this.panelVerticalMap[panelInViewAtScrollY.panelIndex].panelName, this.jumpToPanelCompleted);
        break;
      //
      //////////////////////////////////
      case false:      // Scrolling DOWN 
      //////////////////////////////////
      //
        if (panelInViewAtScrollY.panelIndex === this.panelVerticalMap.length-1) { // We are at the bottom
          GlobalPanelManager.putPanelToTopOfPage(this.panelVerticalMap[this.panelVerticalMap.length-1].panelName, this.jumpToPanelCompleted);
          return;
        }
        //
        // If we leapt over multiple panels then we simply jump to the panel where the current window.scrollY is
        //
        const panelWeScrolledDownFrom = this.whichPanelIsThisScrollLocationIn(window.scrollY - deltaY);
        if (window.scrollY > this.panelVerticalMap[panelWeScrolledDownFrom.panelIndex + 1].bottomOfPanel) {
          GlobalPanelManager.putPanelToTopOfPage(this.panelVerticalMap[panelInViewAtScrollY.panelIndex].panelName, this.jumpToPanelCompleted);
        }
        else
        {
          GlobalPanelManager.putPanelToTopOfPage(this.panelVerticalMap[panelInViewAtScrollY.panelIndex + 1].panelName, this.jumpToPanelCompleted);
        }
        break;
      default:
        break;
    }
  }
  jumpToPanelCompleted() {}
}
//
//*****************************************************************************************************************************************************
// The panel manager is both a page view manager, managing the panels that are visible or dormant, by moving panels into and out of view
// but it is also an aggregator of dynamic jsx code that is then put into the page via the react insertion point through the VisablePageManager via index.js
// Because of this, we also manage the global management of the React-Bootstrap grid which creates, within the containing <div></div> of a specific panel a self-contained
// grid system if the user desires it. On top of that we make the panel system a responsive grid as well for the active panels currently displayed.
// That later global bootstrap grid doesn't do much since the panels on a page are inherently vertically aligned in the panel order of the current display state anyway. 
//
// If a user wanted to create a single page that is fully bootstrap responsive with all the components on the page, he could just use one panel and use
// React-Bootstrap to manage the grid and display priorities of the contained components.
//*****************************************************************************************************************************************************
//
class PanelManager  {

    constructor()
    {
      //
      // Connecting to the visiblePageManager through the GlobalWindowManager
      //
      this.globalVisablePageManagerWasMounted = this.globalVisablePageManagerWasMounted.bind(this);
      this.globalVisablePageManager           = null;
      GlobalWindowManager.registerForGlobalVisablePageManagerNotifyWhenMounted(this);
      //
      // Page MAnagement
      //
      this.myPageManager                      = new pageManager();
      //
      // Panel management stuff
      //
      this.headerPanelOffset                  = 0;
      this.panelArray                         = [];       // array of all managed panel objects
      this.activePanelArray                   = [];       // panel objects of active panels in order of appearance on long form for rendering process
      this.visablePageManagerMountedAndReady  = false;
      this.pageInTransitionFlag               = false;
      //
      // Full Page Scroller Support
      //
      this.fullPageMode                       = false;
      this.panelIsScrollingFlag               = false;
      this.currentPanelAtTopOfPage            = 0;
      this.currentScrollYPosition             = 0;
      this.mobileScrollYTracker               = 0;
      this.mobileTouchMoveFlag                = false;
      this.panelScrollToTopEnded              = this.panelScrollToTopEnded.bind(this);
      //
      // Register event handlers for scrolling
      //
      this.scrollEventHandler                 = this.scrollEventHandler.bind(this);
      this.keyDownEventHandler                = this.keyDownEventHandler.bind(this);
      this.touchEndEventHandler               = this.touchEndEventHandler.bind(this);
      this.touchCancelHandler                 = this.touchCancelHandler.bind(this);
      this.touchStartEventHandler             = this.touchStartEventHandler.bind(this);
      this.touchMoveEventHandler              = this.touchMoveEventHandler.bind(this);
      this.pruneCachedEvents                  = this.pruneCachedEvents.bind(this);
      this.enableScrollListening();
      //
      // Panel Resize Support
      //
      this.windowBeingResizedFlag             = false;
      this.render                             = this.render.bind(this);
      this.windowResized                      = this.windowResized.bind(this);
      //
      // Panel Start up Support
      //
      this.visiblePageManagerDismounted       = this.visiblePageManagerDismounted.bind(this);
      this.waitForRemountOfInActivePanel      = this.waitForRemountOfInActivePanel.bind(this);
      //
      // Panel navigator for full screen panel mode
      //
      this.panelNavigatorManager              = new panelNavigator();
      this.scrollYTrackerManager              = new scrollYTracker(this.activePanelArray);
      //
      // Register the resize handler with the window manager, all clients of panel manager use us to get their notifications
      // we ony send updates to those panels that are active, reducing computation.
      //
      GlobalWindowManager.registerWindowResizeHandler(this.windowResized);
    }
    isPageInTransition()            { return(this.pageInTransitionFlag); }
    setPageIsInTransition()         { this.pageInTransitionFlag = true; }
    setPagehasCompletedTransition() { this.pageInTransitionFlag = false; }
    //
    //////////////////
    // DEBUG Section
    //////////////////
    //
    DEBUG_ActivePanelsToConsole() { console.log("Active Panels in Panel Manager", this.activePanelArray); }
    DEBUG_PutBordersAroundAllActivePanel(colorOverride) {

      var color = "yellow";
      if (colorOverride !== undefined) color = colorOverride;

      for (var i = 0; i < this.activePanelArray.length; i++) { this.activePanelArray[i].setPanelBorder("4px", "solid", color); }
    }
    DEBUG_LabelAllPanelsWithPanelNameAndNumber() {
      for (var i = 0; i < this.activePanelArray.length; i++) { this.activePanelArray[i].labelPanel(i); }
    }
    //
    ///////////////////////////
    // Synchronization Stuff
    ///////////////////////////
    //
    forceRedrawOnDOM() { if (this.globalVisablePageManager != null) this.globalVisablePageManager.forceRedrawOnDOM();}
    globalVisablePageManagerWasMounted (VisualPageManagerInReact) {
      this.globalVisablePageManager = VisualPageManagerInReact;
      this.visablePageManagerMountedAndReady          = true;
      this.globalVisablePageManager.insertRenderMethodIntoDisplay("panelManager", this.render, 0);
      this.globalVisablePageManager.registerForUnmountNotification(this.visiblePageManagerDismounted);
    }
    visiblePageManagerDismounted() {
      console.log("PanelManager: Received Notification of VisablePageManager Dismount");
    }
    //
    //////////////////////////
    // Setting Initial State
    //////////////////////////
    //
    positionPanelsBelowHeader()   { this.headerPanelOffset = (this.globalVisablePageManager != null ? GlobalHeaderManager.getHeaderPanelHeight() : 90); }
    positionPanelsAtTopOfWindow() { this.headerPanelOffset = 0; }
    //
    //////////////////////////
    // Window Resize Handling
    //////////////////////////
    //
    addWindowResizeWatcherToPanel(panelName, notifyMethod) {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      if (notifyMethod === undefined) return;
      panelObj.registerWindowResizeNotifyHandler(notifyMethod);
    }
    windowResized (newSize) { 
      //
      // Check all *active* panels and send notification that the window has resized (if registered for it)
      //
      this.windowBeingResizedFlag = true;
      //
      // For full page mode operation we set the panel height here, then call the panels resize handler, if they try to set the panel height
      // it will be a NoOp so won't trigger scrolling.
      //
      this.disableScrollListening();
      if (this.fullPageMode) {
        for (var index = 0; index < this.activePanelArray.length; index++) {
          this.activePanelArray[index].morphPanelHeight(window.innerHeight, true);
        }
        this.scrollYTrackerManager.calculateVerticalMap();
      }
      for (index = 0; index < this.activePanelArray.length; index++)      { this.activePanelArray[index].notifyPanelWindowResized(newSize, GlobalHeaderManager.getHeaderPanelHeight()); }
      if (this.visablePageManagerMountedAndReady)                         { this.globalVisablePageManager.forceRedrawOnDOM(); }
      if (this.fullPageMode) this.putPanelToTopOfPage(this.activePanelArray[this.currentPanelAtTopOfPage].getPanelName(), this.panelScrollToTopEnded, true);
    }
    panelScrollToTopEnded() { this.enableScrollListening(); this.windowBeingResizedFlag = false; }
    //
    //////////////////////////////////////////////
    // Page Scolling Controls when Option is set
    //////////////////////////////////////////////
    //
    disableScrolling()    { this.scrollYTrackerManager.disableScrolling();  gsap.set(document.body, {overflow: "hidden"}); }
    enableScrolling()     { this.scrollYTrackerManager.enableScrolling();   gsap.set(document.body, {overflow: "visible"}); }
    hidePanelNavigator()  { this.panelNavigatorManager.hidePanelNavigator(); }
    showPanelNavigator()  { this.panelNavigatorManager.showPanelNavigator(); }
    changePanelNavigatorTabColor(newColor)  { this.panelNavigatorManager.changeTabColor(newColor); }
    isPanelScrolling()    { return(this.panelIsScrollingFlag); }
    setFullPageMode(hideNavigatorFlag) {
      if (hideNavigatorFlag === true) this.panelNavigatorManager.hidePanelNavigator();
      this.fullPageMode             = true;
      this.panelIsScrollingFlag     = false;
      this.currentPanelAtTopOfPage  = 0;
      this.currentScrollYPosition   = 0;
      if (GlobalWindowManager.isThisADesktop()) { this.panelNavigatorManager.startNavigator(this.activePanelNameAtThisIndex(0)); }
    }
    unsetFullPageMode() {
      this.fullPageMode             = false;
      this.panelIsScrollingFlag     = false;
      this.currentPanelAtTopOfPage  = 0;
      this.currentScrollYPosition   = 0;
      if (GlobalWindowManager.isThisADesktop()) { this.panelNavigatorManager.stopNavigator(); }
    }
    enableScrollListening() {
      if (this.scrollingDisabledFlag )
      {
        window.removeEventListener('scroll', this.pruneCachedEvents, true);
        window.removeEventListener('keydown', this.pruneCachedEvents, true);
        window.removeEventListener('touchstart', this.pruneCachedEvents, true);
        window.removeEventListener('touchcancel', this.pruneCachedEvents, true);
        window.removeEventListener('touchmove', this.pruneCachedEvents, true);
        window.removeEventListener('touchend', this.pruneCachedEvents, true);

        window.addEventListener('scroll', this.scrollEventHandler, false);
        window.addEventListener('keydown', this.keyDownEventHandler, false);
        window.addEventListener('touchstart', this.touchStartEventHandler, false);
        window.addEventListener('touchcancel', this.touchCancelHandler, false);
        window.addEventListener('touchmove', this.touchMoveEventHandler, false);
        window.addEventListener('touchend', this.touchEndEventHandler, false);

        this.scrollingDisabledFlag = false;
      }
    }
    disableScrollListening() {
      if ( ! this.scrollingDisabledFlag )
      {
        window.removeEventListener('scroll', this.scrollEventHandler, false);
        window.removeEventListener('keydown', this.keyDownEventHandler, false);
        window.removeEventListener('touchstart', this.touchStartEventHandler, false);
        window.removeEventListener('touchcancel', this.touchCancelHandler, false);
        window.removeEventListener('touchmove', this.touchMoveEventHandler, false);
        window.removeEventListener('touchend', this.touchEndEventHandler, false);

        window.addEventListener('scroll', this.pruneCachedEvents, true);
        window.addEventListener('keydown', this.pruneCachedEvents, true);
        window.addEventListener('touchstart', this.pruneCachedEvents, true);
        window.addEventListener('touchcancel', this.pruneCachedEvents, true);
        window.addEventListener('touchmove', this.pruneCachedEvents, true);
        window.addEventListener('touchend', this.pruneCachedEvents, true);

        this.scrollingDisabledFlag = true;
      }
    }
    pruneCachedEvents(event) { event.preventDefault(); }
    getScrollYTrackerManager() { return(this.scrollYTrackerManager); }
    keyDownEventHandler(eventData) {

      if (this.pageInTransitionFlag)                        return;
      if (this.scrollYTrackerManager.isScrollingDisabled()) return;
      
      const currentScrollY = window.scrollY;
      switch (eventData.key)
      {
        case "ArrowDown":
        case "PageDown":
          window.scrollTo(0, currentScrollY + 40); // Will trigger the scroll manager
          break;
        case "ArrowUp":
        case "PageUp":
          window.scrollTo(0, currentScrollY - 40);
          break;
        case " ":
          if (eventData.shiftKey) { window.scrollTo(0, currentScrollY - 40); }
          else                    { window.scrollTo(0, currentScrollY + 40); }
          break;
        default:
          break;
      }
    }
    touchStartEventHandler() {
      this.mobileScrollYTracker = window.scrollY;
      this.mobileTouchMoveFlag  = false;
      console.log("Starting Mobile Touch Scroll, window.scrollY=", window.scrollY);
    }
    touchCancelHandler() {
      this.mobileTouchMoveFlag  = false;
      console.log("Mobile Touch Cancelled, window.scrollY=", window.scrollY);
    }
    touchMoveEventHandler() {
      this.mobileTouchMoveFlag  = true;
      console.log("Touch Moving");
    }
    touchEndEventHandler() {
      if (this.mobileScrollYTracker !== window.scrollY && this.mobileTouchMoveFlag)
      {
        const deltaMovement = Math.abs(this.mobileScrollYTracker - window.scrollY);
        if (this.mobileScrollYTracker < window.scrollY) {       // Scroll down indication
          console.log("Ended Touch Move Scroll DOWN, deltaMovement=", +deltaMovement, window.scrollY);
          this.scrollYTrackerManager.scrollingDetected(+deltaMovement);
        }
        else if (this.mobileScrollYTracker > window.scrollY) {  // Scrolling up indication
          console.log("Ended Touch Move Scroll UP, deltaMovement=", -deltaMovement, window.scrollY);
          this.scrollYTrackerManager.scrollingDetected(-deltaMovement);
        }
      }
      this.mobileTouchMoveFlag  = false;
    }
    scrollEventHandler(event)  {
      //
      // The wheel and touch move events will all post here as well as those event handlers so we center our algorithm here
      //  Note:       if a person never lets his hand off of the scrollbar indicator or the wheel and "just keeps scrolling" he probably
      //              wants to keep scroling past the next panel. This algorithm will preserve that behavior by ignoring the scroll events
      //              while gsap'ing a page to the next panel but, if he's still tugging at the scrollbar or wheel then we'll jump again
      //              as soon as the page has finished the panel scroll and we start looking at scroll events again.
      //
      //  Algorithm:  (1) receive a scroll event if on desktop (on mobile we use touchEndEventHandler)
      //              (2) check direction of scroll and move to the panel above or below this panel depending on direction
      //              (3) ignore scroll events while panel is in motion until we get signaled it has stopped
      //              (4) repeat from (1) forever
      //
      if (this.pageInTransitionFlag)                return;
      if (this.windowBeingResizedFlag)              return;
      if (GlobalWindowManager.isThisMobileDevice()) return;
      if (this.currentScrollYPosition === window.scrollY || this.panelIsScrollingFlag === true) return;

      const deltaMovement = Math.abs(this.currentScrollYPosition - window.scrollY);
      if (this.currentScrollYPosition < window.scrollY) {       // Scroll down indication
        this.scrollYTrackerManager.scrollingDetected(+deltaMovement);
      }
      else if (this.currentScrollYPosition > window.scrollY) {  // Scrolling up indication
        this.scrollYTrackerManager.scrollingDetected(-deltaMovement);
      }
    }
    putPanelToTopOfPage(panelName, callbackWhenCompleteFunction, forceFlag) { // Force flag is for window resize
      //
      // Check the inputs
      //
      if (panelName === null || panelName === undefined)  return (false);
      if (callbackWhenCompleteFunction === undefined ||
          callbackWhenCompleteFunction == null)           return (false);
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null)                               return (false);
      //
      // Ignore if this panel is not active
      //
      if (! panelObj.isPanelActive(panelName))            return (false);
      if (! panelObj.panelIsMounted(panelName))           return (false);
      if (this.panelIsScrollingFlag === true)             return (false);
      //
      // Determine the distance it must travel by checking where the encapsulating div is on its current scroll
      // then find out where the indicated panel is, then move it to the top of the page.
      //
      var distanceToScroll = 0;
      for (var i = 0; i < this.indexOfActivePanel(panelName); i++) {
        var panelAtLocationI = this.activePanelObjectAtThisIndex(i);
        distanceToScroll = distanceToScroll + panelAtLocationI.getPanelHeight();
      }
      //
      // Okay we have to scroll
      //
      panelObj.setPanelIsInMotion();
      this.panelIsScrollingFlag = true;
      this.currentScrollYPosition = distanceToScroll;
      var forceSetOverTo = false;
      if (forceFlag !== undefined) forceSetOverTo = forceFlag;
      this.disableScrollListening();

      this.panelNavigatorManager.advanceNavigatorTo(panelName);

      if (forceSetOverTo) {
        gsap.set(window, { scrollTo: { y: distanceToScroll },
                           onStart: ()=>    {
                                              if (this.activePanelArray[this.currentPanelAtTopOfPage] !== undefined && this.activePanelArray[this.currentPanelAtTopOfPage] != null) {
                                                if (this.activePanelArray[this.currentPanelAtTopOfPage].getPanelName() !== undefined)
                                                  this.panelWentOutOfFocus(this.activePanelArray[this.currentPanelAtTopOfPage].getPanelName()); 
                                              }
                                              this.panelCameIntoFocus(panelName);
                                            },
                           onComplete: ()=>  {  this.panelHasEndedItsScrollToTop(panelName);
                                                callbackWhenCompleteFunction();}});
      }
      else {
        gsap.to(window, { duration: 1, ease: "power3.out", scrollTo: { y: distanceToScroll },
                          onStart: ()=>     {
                                              if (this.activePanelArray[this.currentPanelAtTopOfPage] !== undefined && this.activePanelArray[this.currentPanelAtTopOfPage] != null) {
                                                if (this.activePanelArray[this.currentPanelAtTopOfPage].getPanelName() !== undefined)
                                                  this.panelWentOutOfFocus(this.activePanelArray[this.currentPanelAtTopOfPage].getPanelName()); 
                                              }
                                              this.panelCameIntoFocus(panelName);
                                            },
                          onComplete: ()=>  {   this.panelHasEndedItsScrollToTop(panelName);
                                                callbackWhenCompleteFunction();}});

      }
    }
    panelHasEndedItsScrollToTop(panelName) {
      this.currentPanelAtTopOfPage    = this.indexOfActivePanel(panelName);
      this.currentScrollYPosition     = window.scrollY;
      this.getPanelByName(panelName).setPanelIsAtRest();
      this.panelIsScrollingFlag       = false;
      this.enableScrollListening();
    }
    //
    /////////////////////////
    // End Scrolling stuff
    /////////////////////////
    //
    //
    ////////////////////////////////////
    // Panel is in Focus Notifications
    ////////////////////////////////////
    //
    addPanelInFocusWatcherToPanel(panelName, notifyMethod) {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      if (notifyMethod === undefined) return;
      panelObj.registerPanelInFocusNotifyHandler(notifyMethod);
    }
    panelCameIntoFocus(panelName) {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      panelObj.notifyPanelCameIntoFocus();
    }
    panelWentOutOfFocus(panelName) {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      panelObj.notifyPanelWentOutOfFocusOnPage();
    }
    panelGoingOutOfFocusAndOutOfDom(panelName) {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      panelObj.notifyPanelGoingOutOfFocusAndOutOfDom();
    }
    checkIsThisPanelInFocus(panelName) {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      return(panelObj.checkIsThisPanelInFocus());
    }
    //
    ///////////////////////////
    // Scroll Up Indicators
    ///////////////////////////
    //
    getScrollTopIndicatorHeight(panelName) {  
      if (panelName === undefined)            return (0);
      if (panelName == null)                  return (0);
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null)                   return (0);
      return(panelObj.getScrollTopIndicatorHeight());
    }
    addScrollUpIndicatorToPanelAt(panelName, height, top) {  
      if (panelName === undefined)            return (false);
      if (panelName == null)                  return (false);
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null)                   return (false);
      panelObj.addScrollUpIndicatorToPanelAt(height, top);
    }
    startScrollUpIndicatorAnimation(panelName) {  
      if (panelName === undefined)            return (false);
      if (panelName == null)                  return (false);
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      panelObj.startScrollUpIndicatorAnimation();
    }
    stopScrollUpIndicatorAnimation(panelName) {  
      if (panelName === undefined)            return (false);
      if (panelName == null)                  return (false);
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      panelObj.stopScrollUpIndicatorAnimation();
    }
    addScrollDownIndicatorToPanelAt(panelName, height, bottom) {  
      if (panelName === undefined)            return (false);
      if (panelName == null)                  return (false);
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null)                   return (false);
      panelObj.addScrollDownIndicatorToPanelAt(height, bottom);
    }
    startScrollDownIndicatorAnimation(panelName) {  
      if (panelName === undefined)            return (false);
      if (panelName == null)                  return (false);
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      panelObj.startScrollDownIndicatorAnimation();
    }
    stopScrollDownIndicatorAnimation(panelName) {  
      if (panelName === undefined)            return (false);
      if (panelName == null)                  return (false);
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      panelObj.stopScrollDownIndicatorAnimation();
    }
    //
    ///////////////////////////////////
    // General Page Management Routines
    ///////////////////////////////////
    //
    makePageActive(pageName, callBackRoutine) { this.myPageManager.makePageActive(pageName, callBackRoutine); }
    printAllPanelsInPage(pageName, comment) { this.myPageManager.printAllPanelsInPage(pageName, comment); }
    //
    ///////////////////////////
    // General Panel Routines
    ///////////////////////////
    //
    getCurrentWindowSize()  { return(GlobalWindowManager.getCurrentWindowSize()); }
    setCallbackIfPageBeingTransitioned(pageName, callbackMethod) { return(this.myPageManager.setCallbackIfPageBeingTransitioned(pageName, callbackMethod)); }
    addPanel(panelName, pageName)   {    
      if (pageName  === undefined)        {return (false);}    
      if (pageName  ==  null)             {return (false);}
      if (panelName === undefined)        {return (false);}
      if (panelName ==  null)             {return (false);}
      if (this.doesPanelExist(panelName)) {return (false);}

      var newPanelObj = new Panel(panelName, pageName);
      this.panelArray.push(newPanelObj);
      this.myPageManager.addPanelToPage(pageName, panelName); // All Panels must be associated with a Page name
      return(newPanelObj);
    }
    getPanelHeight(panelName) {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return(0);
      return(panelObj.getPanelHeight());
    }
    setContainerDiv(panelName, containerName) {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      panelObj.setContainerDiv(containerName);
    }
    isPanelInMotion(panelName) {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return(false);
      return(panelObj.isPanelInMotion());
    }
    getPanelByName(panelNameSearch) {
      for (var indexOfMatch = 0; indexOfMatch < this.panelArray.length; indexOfMatch++)
      {
        if (this.panelArray[indexOfMatch].getPanelName() ===  panelNameSearch) { 
          return (this.panelArray[indexOfMatch]);
        }
      }
      return (null);
    }
    activePanelObjectAtThisIndex(index)   {
      if (index >= 0 && index < this.activePanelArray.length) {
        return(this.activePanelArray[index]);
      }
      return(null);
    }
    getPanelNameByIndex(index)      {
      var panelName = this.activePanelNameAtThisIndex(index);
      if (panelName == null) return(null);
      return(panelName);
    }
    activePanelNameAtThisIndex(index)   {
      if (index >= 0 && index <= this.activePanelArray.length - 1) {
        return(this.activePanelArray[index].getPanelName());
      }
      return(null);
    }
    doesPanelExist(panelNameSearch) {
      for (var indexOfMatch = 0; indexOfMatch < this.panelArray.length; indexOfMatch++)
      {
        if (this.panelArray[indexOfMatch].getPanelName() ===  panelNameSearch) { return (true); }
      }
      return (false);
    }
    indexOfPanel(panelNameSearch)   {
      for (var indexOfMatch = 0; indexOfMatch < this.panelArray.length; indexOfMatch++)
      {
        if (this.panelArray[indexOfMatch].getPanelName() ===  panelNameSearch) { return (indexOfMatch); }
      }
      return (-1);
    }
    indexOfActivePanel(panelNameSearch)   {
      for (var indexOfMatch = 0; indexOfMatch < this.activePanelArray.length; indexOfMatch++)
      {
        if (this.activePanelArray[indexOfMatch].getPanelName() ===  panelNameSearch) { return (indexOfMatch); }
      }
      return (-1);
    }
    totalNumberOfActivePanels()     { return(this.activePanelArray.length); }
    setPanelBackgroundImage(panelName, imageRef, optionalNotificationMethod)           {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return(false);
      return(panelObj.setPanelBackgroundImage(imageRef, optionalNotificationMethod));
    } 
    isPanelActive(panelNameSearch)  {
      for (var indexOfMatch = 0; indexOfMatch < this.activePanelArray.length; indexOfMatch++)
      {
        if (this.activePanelArray[indexOfMatch].getPanelName() ===  panelNameSearch) { return (true); }
      }
      return (false);
    }
    makePanelActive(enteringPanelName, inThisOrderOfPanelLineup, enterMethod, notificationMethodForRemount) {
      //
      // The only way for a new panel to be mounted is to be activated and put into the JSX string sent
      // to React via the visualPageManager. That creates a conundrum if we want to create a new panel and
      // insert it with clever entering GSAP manuevers or remount an old one that has been made inActive.
      // Therefore we have to accomodate that process.
      //      
      var panelObj = this.getPanelByName(enteringPanelName);
      if (panelObj == null)         return (false);
      if (panelObj.isPanelActive()) return (false);
      //
      // If the page belonging to this panel is being transitioed out then we prevent the page from becoming active during the transition
      //
      if (this.isPageInTransition() && panelObj.getPageName() !== this.myPageManager.currentActivePage()) return;
      
      if (notificationMethodForRemount !== undefined)  panelObj.setNotificationMethodForRemount(notificationMethodForRemount);  
      
      var approvedEnterMethod = (enterMethod === undefined ? "instantaneous" : enterMethod);
      //
      // Set up for rendering
      //
      panelObj.setPanelIsInMotion();
      panelObj.makePanelActive();
      panelObj.setPanelPositionParameter("relative");
      if (panelObj.getPanelDivRef() == null && approvedEnterMethod !== "instantaneous") { 
        //
        // We have to remount this bugger before we reenter it into the page
        // (if we are directed to animate it's arrival) we'll do that by putting
        // it into the dom at it's final location in the active array then launch
        // a setTimeout method to wait for it to mount before we run the animation
        //
        const startingPanelHeight = panelObj.getPanelHeight();
        panelObj.setTopLeft(0, 0);
        panelObj.setZIndex(0);
        panelObj.setOpacity(0.0);
        panelObj.morphPanelHeight(0,true);
        this.spliceNewPanelIntoActiveList(panelObj, inThisOrderOfPanelLineup);

        if (this.globalVisablePageManager != null) this.globalVisablePageManager.forceRedrawOnDOM();

        setTimeout(this.waitForRemountOfInActivePanel, 100, enteringPanelName, enterMethod, startingPanelHeight);
        return(true);
      }
      //
      // This is the instantaneous case
      //
      panelObj.setTopLeft(0, 0);
      panelObj.setZIndex(1);
      panelObj.setOpacity(1.0);
      this.spliceNewPanelIntoActiveList(panelObj, inThisOrderOfPanelLineup);
      this.finalizeTheEntrance(enteringPanelName);
      return(true);
    }
    waitForRemountOfInActivePanel(panelName, enterMethod, startingPanelHeight) {
      var panelObj    = this.getPanelByName(panelName);
      var panelDivRef = panelObj.getPanelDivRef();

      if (panelDivRef == null)  setTimeout(this.waitForRemountOfInActivePanel, 100, panelName, enterMethod, startingPanelHeight);
      // 
      // The panel is now mounted so we can run our animation entrance
      //
      var panelHeightOnReentry = (startingPanelHeight === 0 ? 100 : startingPanelHeight)

      panelObj.setZIndex(1);
      var enteringDistance =   document.getElementById('root').offsetWidth + 100;
      switch (enterMethod)
      {
        case "enterStageLeft":
          gsap.set(panelDivRef, {x: -enteringDistance})
          panelObj.setOpacity(1.0);
          gsap.timeline()
            .to(panelDivRef, {height: panelHeightOnReentry, duration: 1.0})
            .to(panelDivRef, {x: 0, duration: 1.0, onComplete: ()=> { this.finalizeTheEntrance(panelName, panelHeightOnReentry); }}, ">");
          break;
        case "enterStageRight":
          gsap.set(panelDivRef, {x: +enteringDistance})
          panelObj.setOpacity(1.0);
          gsap.timeline()
            .to(panelDivRef, {height: panelHeightOnReentry, duration: 1.0})
            .to(panelDivRef, {x: 0, duration: 1.0, onComplete: ()=> { this.finalizeTheEntrance(panelName, panelHeightOnReentry); }}, ">");
          break;
        default:
        case "gracefulEnter":
          gsap.to(panelDivRef, {height: panelHeightOnReentry, opacity: 1.0, duration: 0.5, onComplete: ()=> { this.finalizeTheEntrance(panelName, panelHeightOnReentry); }});
          break;
      }
      this.finalizeTheEntrance(panelName, panelHeightOnReentry)
    }
    spliceNewPanelIntoActiveList(panelObj, putItHere) {
      if (putItHere <= this.activePanelArray.length - 1)  { this.activePanelArray.splice(putItHere, 0, panelObj); }
      else                                                { this.activePanelArray.push(panelObj); }
      this.panelNavigatorManager.panelListHasChanged(this.activePanelArray);
    }
    finalizeTheEntrance(enteringPanelName, panelHeightOnReentry) {
      var panelObj = this.getPanelByName(enteringPanelName);
      panelObj.morphPanelHeight(panelHeightOnReentry,true);
      panelObj.setPanelIsAtRest();
      if (this.indexOfActivePanel(enteringPanelName) === 0) this.panelCameIntoFocus(enteringPanelName);
      this.scrollYTrackerManager.addedActiveAndMountedPanel(this.activePanelArray);
      if (panelObj.getNotificationMethodForRemount() != null) panelObj.getNotificationMethodForRemount()(enteringPanelName);          
      if (this.globalVisablePageManager != null) this.globalVisablePageManager.forceRedrawOnDOM();
    }
    makePanelInActive (exitingPanelName, exitmethod) {
      // 
      // We have to deal with the asynchrony inherent in tweens and javascript that "keeps on going"
      // This may sometimes cause the user to keep trying to take this panel to inActive mode
      //
      const panelObj = this.getPanelByName(exitingPanelName);

      if (panelObj == null)               return (false);
      if ( ! panelObj.panelIsMounted())   return (false);
      if ( ! panelObj.isPanelActive())    return (true);
      //
      // Find the index of the panel and splice it out but do a graceful exit while your at it.
      //      
      for (var i = 0; i < this.activePanelArray.length; i++) {
        if (this.activePanelArray[i] === panelObj)
        {
          const exitingPanelDivRef  = this.activePanelArray[i].getPanelDivRef();
          const exitDistance        = exitingPanelDivRef.offsetWidth + 1000;
          switch (exitmethod)
          {
            default:
            case "instantaneous":
              this.finalizeThePruning(exitingPanelName);
              break;
            case "exitStageLeft":
              panelObj.setPanelIsInMotion();
              gsap.timeline()
                .to(exitingPanelDivRef, {x: -exitDistance, duration: 1.0, onComplete: ()=> { this.finalizeThePruning(exitingPanelName); }});
              break;
            case "exitStageRight":
              panelObj.setPanelIsInMotion();
              gsap.timeline()
                .to(exitingPanelDivRef, {x: +exitDistance, duration: 1.0, onComplete: ()=> { this.finalizeThePruning(exitingPanelName); }});
              break;
              case "oldTimeTVCollapse":
                // 
                // For this one we need a spacer to hold the panel in position while our exiting panel shrinks and passes away to dust,
                // then we collapse the space gracefully, so we float the current panel over the old one while inserting the space into it's place
                // when that is done we do our magic
                //
                panelObj.setPanelIsInMotion();
                this.floatPanelOverExistingSpace(panelObj, true);
                gsap.timeline()
                  .to(exitingPanelDivRef, {height: 3.0, width: 200, duration: 1.0, onComplete: ()=> { this.finalizeThePruning(exitingPanelName); }});
                break;
            case "gracefulCollapse":
              panelObj.setPanelIsInMotion();
              gsap.to(exitingPanelDivRef, {height: 0.0, opacity: 0.0, duration: 0.5, onComplete: ()=> { this.finalizeThePruning(exitingPanelName); }});
              break;
          }
          return(true);
        }
      }
    }
    finalizeThePruning(exitingPanelName) {
      var panelObj = this.getPanelByName(exitingPanelName);
      panelObj.setPanelIsAtRest();

      if (this.isPanelActive(exitingPanelName)) {
        //
        // If a panel is leaving the nest we need to alert the next panel down that it is coming into focus
        //
        const exitingPaneIndex = this.indexOfActivePanel(exitingPanelName);
        const nextPanelName     = GlobalPanelManager.activePanelNameAtThisIndex(exitingPaneIndex+1);
        if (nextPanelName != null) this.panelCameIntoFocus(nextPanelName);

        this.activePanelArray.splice(this.indexOfActivePanel(exitingPanelName), 1); 
        this.panelNavigatorManager.panelListHasChanged(this.activePanelArray);
        panelObj.makePanelInActive();
        this.scrollYTrackerManager.removedActiveAndMountedPanel(this.activePanelArray);         
        this.globalVisablePageManager.forceRedrawOnDOM();
      }
    }
    insertJSXStringIntoPanel(panelName, JSXstring) {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      panelObj.insertJSXString(JSXstring);
    }
    getTopPanelDivRef()  { return(this.activePanelArray[0].getPanelDivRef()); }
    getThisPanelDivRef(panelName)  {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      return(panelObj.getPanelDivRef());
    }
    getThisPanelContainerDivRef(panelName) {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      return(panelObj.getContainerDivRef());
    }
    setPanelHeight(panelName, newHeight)    { this.morphPanelHeight (panelName, newHeight, true); }
    morphPanelHeight(panelName, newHeight, setOrMorphFlag)  {
        var flag = (setOrMorphFlag ? true : false);
        var panelObj = this.getPanelByName(panelName);
        if (panelObj == null) return;
        panelObj.morphPanelHeight(newHeight, flag);
    }
    getPanelColor(panelName)  { 
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return(null);
      return(panelObj.getPanelColor());
    }
    setPanelColor(panelName, newColor)    { this.morphPanelColor (panelName, newColor, true); }
    morphPanelColor(panelName, newColor, setOrMorphFlag) {
      var flag = (setOrMorphFlag ? true : false);
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      panelObj.morphPanelColor(newColor, flag);
    }
    setPanelBorder(panelName, width, pattern, color) {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      panelObj.setPanelBorder(width, pattern, color);
    }
    clearPanelBorder(panelName) {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj == null) return;
      panelObj.clearPanelBorder();
    }
    isThisPanelMounted(panelName) {
      var panelObj = this.getPanelByName(panelName);
      if (panelObj === null) return(false);
      return(panelObj.panelIsMounted());
    }
    render() {
      var renderString        = [];
      var activePanelIndex    = 0;
      while (activePanelIndex < this.activePanelArray.length) {
        renderString.push(this.activePanelArray[activePanelIndex].renderMyPanel());
        activePanelIndex++;
      }
      const navigatorString   = this.panelNavigatorManager.render((this.globalVisablePageManager != null ? GlobalHeaderManager.getHeaderPanelHeight() : 90));
      return (
          <div
            id={"PanelManagerEnclosingContainerID"}
            key={"PanelManagerEnclosingContainerKey"}
            style={{
              overflow:       "hidden", // This hides the vertical and horizontal Scroll Bars (and any stuff that escapes the window)
              paddingLeft:    0,
              paddingRight:   0,
              paddingTop:     0,
              paddingBottom:  0,
              marginTop:      this.headerPanelOffset,
              marginLeft:     0,
              marginRight:    0
            }}>
              {renderString}
              {navigatorString}
          </div>
      );
    }
  } // End class PanelManager  
  
const GlobalPanelManager = new PanelManager();
export default GlobalPanelManager;